import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import * as powerbi from 'powerbi-client';
import { AuthContext } from '../../context/AuthContext';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { BsFullscreen, BsPrinter, BsFilter } from 'react-icons/bs';
import loadingGif from '../../assets/Loading.gif';

const Dashboards = () => {
  const { user } = useContext(AuthContext);
  const reportContainerRef = useRef(null);
  const [dashboards, setDashboards] = useState([]);
  const [filteredDashboard, setFilteredDashboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedGrupo, setSelectedGrupo] = useState('');
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api-dashflow/dashboards`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        // Filtra os dashboards com base no cluster do usuário e define o padrão
        const dashboardsByCluster = response.data.filter(dashboard => dashboard.cluster === user.cluster);
        const defaultDashboard = dashboardsByCluster.find(dashboard => dashboard.isPrincipal) || dashboardsByCluster[0];

        setDashboards(response.data);
        setFilteredDashboard(defaultDashboard);
      } catch (error) {
        setError('Erro ao carregar os dados');
      } finally {
        setLoading(false);
      }
    };
    fetchDashboards();
  }, [user]);

  useEffect(() => {
    const loadDashboard = async () => {
      if (!filteredDashboard || !reportContainerRef.current) return;
  
      setLoadingDashboard(true);
      setErrorMessage(null);
  
      try {
        const workspaceId = Array.isArray(filteredDashboard.WorkspaceId)
          ? filteredDashboard.WorkspaceId[0]
          : filteredDashboard.WorkspaceId;
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api-dashflow/dashboardUrl`,
          {
            params: {
              reportId: filteredDashboard.report_id,
              workspaceId: workspaceId,
              cluster: filteredDashboard.cluster, // Inclui o cluster no request
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
  
        const { embedUrl, token } = response.data;
  
        if (!embedUrl || !token) {
          throw new Error('Informações insuficientes para carregar o dashboard.');
        }
  
        const embedConfiguration = {
          type: 'report',
          embedUrl: embedUrl,
          tokenType: powerbi.models.TokenType.Aad,
          accessToken: token,
          id: filteredDashboard.report_id,
          settings: {
            panes: { filters: { visible: false }, pageNavigation: { visible: true } },
          },
        };
  
        const powerbiService = new powerbi.service.Service(
          powerbi.factories.hpmFactory,
          powerbi.factories.wpmpFactory,
          powerbi.factories.routerFactory
        );
  
        const reportContainer = reportContainerRef.current;
        powerbiService.reset(reportContainer);
  
        // Embed o relatório e captura o objeto do relatório
        const report = powerbiService.embed(reportContainer, embedConfiguration);
  
        // Adiciona evento de mudança de página
        report.on('pageChanged', async (event) => {
          const newPage = event.detail.newPage;
  
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/api-dashflow/logPageChange`,
              {
                reportId: filteredDashboard.report_id,
                pageName: newPage.displayName,
                userId: user.id, // ID do usuário logado
                cluster: filteredDashboard.cluster, // Cluster atual
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            );
  
            console.log('Mudança de página registrada com sucesso:', newPage.displayName);
          } catch (error) {
            console.error('Erro ao registrar mudança de página:', error.message);
          }
        });
  
        setLoadingDashboard(false);
      } catch (error) {
        setErrorMessage('Erro ao carregar o dashboard.');
        console.error('Erro ao carregar o dashboard:', error.message);
      } finally {
        setLoadingDashboard(false);
      }
    };
  
    loadDashboard();
  }, [filteredDashboard]);
  

  const handleFullScreen = () => {
    const reportContainer = reportContainerRef.current;
    if (reportContainer.requestFullscreen) {
      reportContainer.requestFullscreen();
    } else if (reportContainer.webkitRequestFullscreen) {
      reportContainer.webkitRequestFullscreen();
    } else if (reportContainer.msRequestFullscreen) {
      reportContainer.msRequestFullscreen();
    } else if (reportContainer.mozRequestFullScreen) {
      reportContainer.mozRequestFullScreen();
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const toggleFilter = () => setIsFilterVisible(!isFilterVisible);

  const gruposFiltrados = user.isAdmin
    ? [...new Set(dashboards.map((dashboard) => dashboard.grupo))]
    : [...new Set(dashboards.filter((d) => d.cluster === user.cluster).map((dashboard) => dashboard.grupo))];

  const dashboardsFiltrados = dashboards.filter(
    (dashboard) =>
      (user.isAdmin || dashboard.cluster === user.cluster) &&
      (selectedGrupo ? dashboard.grupo === selectedGrupo : true)
  );

  const handleDashboardSelect = (dashboard) => {
    setSelectedDashboard(dashboard.report_name);
    setFilteredDashboard(dashboard);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--text-color)' }}>
        <img src={loadingGif} alt="Carregando..." />
        <p>Carregando dados...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--danger-color)' }}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="Dashboards" style={{ minHeight: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <div>
        <div 
          className="ctn-button" 
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}
        >
          <Button onClick={toggleFilter} title="Filtros" variant="outline-primary">
            <BsFilter />
          </Button>
          <Button onClick={handleFullScreen} title="Tela cheia" variant="outline-primary">
            <BsFullscreen />
          </Button>
          <Button onClick={handlePrint} title="Imprimir" variant="outline-primary">
            <BsPrinter />
          </Button>
        </div>

        {isFilterVisible && (
          <div className="filters">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {selectedDashboard || 'Selecione um Grupo e Dashboard'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {gruposFiltrados.map((grupo, index) => (
                  <Dropdown.Item
                    key={index}
                    as="div"
                    onMouseEnter={() => setSelectedGrupo(grupo)}
                    style={{ position: 'relative', padding: '10px', fontWeight: 'bold' }}
                  >
                    {grupo}
                    {selectedGrupo === grupo && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '100%',
                          background: '#fff',
                          border: '1px solid rgba(0,0,0,.125)',
                          padding: '10px',
                          minWidth: '200px',
                          zIndex: '1000',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                      >
                        {dashboardsFiltrados
                          .filter((dashboard) => dashboard.grupo === grupo)
                          .map((dashboard, index) => (
                            <div
                              key={index}
                              style={{
                                padding: '8px',
                                cursor: 'pointer',
                                color: selectedDashboard === dashboard.report_name ? '#007bff' : 'black',
                                background: selectedDashboard === dashboard.report_name ? '#f1f1f1' : 'transparent',
                              }}
                              onClick={() => handleDashboardSelect(dashboard)}
                            >
                              {dashboard.report_name}
                            </div>
                          ))}
                      </div>
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {filteredDashboard && (
          <div style={{ backgroundColor: 'var(--background-color)', padding: '20px', borderRadius: '8px' }}>
            {loadingDashboard && <p>Carregando dashboard...</p>}
            {errorMessage && <p>{errorMessage}</p>}
            <div id="reportContainer" ref={reportContainerRef} style={{ height: 'calc(100vh - 120px)', width: '100%' }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboards;