

// src/page/welcome/WelcomePage.js
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import { jwtDecode } from 'jwt-decode';
import './Login.css'; // Usar a mesma folha de estilo do Login

const WelcomePage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const error = queryParams.get('error');

    if (token) {
      try {
        // Decodifica o token JWT para obter os dados do usuário
        const decodedToken = jwtDecode(token);
        
        // Ajuste isAdmin de boolean para numérico
        decodedToken.isAdmin = decodedToken.isAdmin ? 1 : 0;
    
        // Armazena o token no localStorage
        localStorage.setItem('auth-token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        // Atualiza o contexto de autenticação com o token e o usuário decodificado
        login({ token, user: decodedToken });
    
        // Redireciona o usuário para o dashboard após um pequeno atraso
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      } catch (e) {
        setErrorMessage('Erro ao processar o token. Tente novamente.');
        console.error('Token decoding error:', e);
      }
    }
     else if (error) {
      let errorMessage = 'Falha na autenticação. Por favor, tente novamente.';
      if (error === 'invalid_token') {
        errorMessage = 'Token inválido. Tente fazer login novamente.';
      }
      setErrorMessage(errorMessage);
    }
  }, [location.search, login, navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-box">
          <h2>Bem-vindo!</h2>
          <p>Estamos processando seu login, por favor, aguarde...</p>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <img src="/logo-cliente.png" alt="Logo" className="logo" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
