import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showExternalLogin, setShowExternalLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login, setUserEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Captura o token JWT ou o erro após o redirecionamento do OAuth2

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const error = queryParams.get('error');
  
    if (token) {
      // Decodifica o token JWT para obter as informações do usuário
      const decodedToken = jwtDecode(token);
    
      // Ajuste isAdmin de boolean para numérico
      decodedToken.isAdmin = decodedToken.isAdmin ? 1 : 0;
    
      // Agora, passe o token e o usuário decodificado no login
      login({ token, user: decodedToken });
    
      navigate('/dashboard');
    }
    
  
    if (error) {
      let errorMessage = 'Falha na autenticação. Por favor, tente novamente.';
      if (error === 'user_not_registered') {
        errorMessage = 'Usuário não cadastrado.';
      } else if (error === 'no_email') {
        errorMessage = 'Não foi possível obter o email do usuário.';
      }
      setErrorMessage(errorMessage);
    }
  }, [location.search, login, navigate]);
  

  // Função para enviar o login e senha
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      const payload = { email, password };
      const response = await api.post('/api-login/login', payload);

      // Armazene o token no localStorage
      localStorage.setItem('token', response.data.token);
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

      // Atualiza o contexto de autenticação com os dados recebidos
      login(response.data);
      setUserEmail(email);

      if (response.data.user.firstLogin) {
        navigate('/firstLogin');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login error:', error.response?.data);
      setErrorMessage(error.response?.data?.message || 'Email ou senha inválidos');
    }
  };

  // Função para redirecionar para o login via Microsoft OAuth2
  const handleMicrosoftLogin = async () => {
    try {
      const response = await api.get('/api-login/auth/microsoft');
      const { loginUrl } = response.data;
      window.location.href = loginUrl;
    } catch (error) {
      console.error('Erro ao redirecionar para o login do Microsoft OAuth2:', error);
      setErrorMessage('Erro ao iniciar o login via Microsoft. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-box">
          <h2>Área do Cliente</h2>
          <p>Escolha o método de acesso mais adequado para você</p>
          <img src="/logo-cliente.png" alt="Logo" className="logo" />
          <div className="btn-container">
            <button onClick={handleMicrosoftLogin} className="btn btn-brown">Acesso usuário HNT</button>

            {!showExternalLogin && (
              <button onClick={() => setShowExternalLogin(true)} className="btn btn-brown">Acesso usuário Externo</button>
            )}
          </div>
          {showExternalLogin && (
            <form onSubmit={handleSubmit} className="login-form">
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-brown">Login</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
