import axios from 'axios';
import io from 'socket.io-client';

// Configuração do Axios para fazer requisições à API
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Usando a variável de ambiente correta
});

// Interceptor para adicionar o token de autenticação no cabeçalho das requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Funções relacionadas à API de Dashboards

export const getAllDashboards = async () => {
  const response = await api.get('/api-dashflow/manage-dashboards');
  return response.data;
};

export const getActiveDashboardsCount = async () => {
  const response = await api.get('/api-dashflow/manage-dashboards/active-count');
  return response.data;
};

export const getInactiveDashboardsCount = async () => {
  const response = await api.get('/api-dashflow/manage-dashboards/inactive-count');
  return response.data;
};

export const createOrUpdateDashboard = async (dashboard) => {
  try {
    const response = await api.post('/api-dashflow/save-dashboard', dashboard);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      throw new Error('Dashboard já existe.');
    }
    throw error;
  }
};

export const updateDashboard = async (id, dashboard) => {
  const response = await api.put(`/api-dashflow/dashboards/${id}`, dashboard);
  return response.data;
};

export const deleteDashboard = async (id) => {
  const response = await api.delete(`/api-dashflow/dashboards/${id}`);
  return response.data;
};

export const getWorkspaces = async () => {
  const response = await api.get('/api-dashflow/workspaces');
  return response.data;
};

export const updateWorkspaces = async () => {
  const response = await api.get('/api-dashflow/update-workspaces');
  return response.data;
};

export const getDashboardsByWorkspace = async (workspaceId) => {
  const response = await api.get(`/api-dashflow/dashboards/${workspaceId}`);
  return response.data;
};

export const updateDashboards = async (workspaceId) => {
  const response = await api.get(`/api-dashflow/update-dashboards/${workspaceId}`);
  return response.data;
};

// Funções relacionadas à API de Usuários

export const fetchUsers = async (token, setUsers, setFilteredUsers, setActiveUsers, setInactiveUsers, setAdminUsers, setLoading) => {
  try {
    const response = await api.get('/api-dashflow/users', {
      headers: { 'x-api-token': token }
    });

    if (Array.isArray(response.data)) {
      const usersData = response.data;
      setUsers(usersData);
      setFilteredUsers(usersData);
      setActiveUsers(usersData.filter(user => user.status).length);
      setInactiveUsers(usersData.filter(user => !user.status).length);
      setAdminUsers(usersData.filter(user => user.access_level === 1).length);
    } else {
      console.error('Unexpected response data:', response.data);
      setUsers([]);
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    setUsers([]);
  } finally {
    setLoading(false);
  }
};

export const fetchUserLimit = async (token, setUserLimit) => {
  try {
    const response = await api.get('/api-dashflow/license-limit', {
      headers: { 'x-api-token': token }
    });
    setUserLimit(response.data.limit);
  } catch (error) {
    console.error('Error fetching user limit:', error);
    setUserLimit(0);
  }
};

export const applyFilters = (users, filters, setFilteredUsers) => {
  const { status, name, email, cluster } = filters;
  const filtered = users.filter(user => {
    return (
      (!status || (status === 'active' && user.status) || (status === 'inactive' && !user.status)) &&
      (!name || user.name.toLowerCase().includes(name.toLowerCase())) &&
      (!email || user.email.toLowerCase().includes(email.toLowerCase())) &&
      (!cluster || user.cluster.toLowerCase().includes(cluster.toLowerCase()))
    );
  });
  setFilteredUsers(filtered);
};

// Funções relacionadas à autenticação e comunicação em tempo real (Socket.IO)

export const socket = io(process.env.REACT_APP_API_URL);

export const registerSocket = (userId) => {
  socket.emit('register', { userId });
};

socket.on('logout', (message) => {
  alert(message);
  logoutUser();
});

export const logoutUser = () => {
  localStorage.removeItem('auth-token');
  window.location.href = '/login';
};

export const loginUser = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    if (response.status === 200) {
      const { token, user } = response.data;
      localStorage.setItem('auth-token', token);
      registerSocket(user.id);
      window.location.href = '/';
    } else {
      alert('Login failed');
    }
  } catch (error) {
    console.error('Error during login:', error);
  }
};

export const initiatePowerBILogin = async () => {
  try {
    const response = await api.get('/api-dashflow/login-oauth');
    if (response.status === 200) {
      const width = 500;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;
      window.open(response.data.authUrl, 'Login OAuth2', `width=${width},height=${height},top=${top},left=${left}`);
    }
  } catch (error) {
    console.error('Erro ao iniciar o login do Power BI:', error);
  }
};

export default api;
