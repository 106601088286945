// frontend/src/components/AccessDenied.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';

const AccessDenied = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/dashboard');
  };

  return (
    <div className="access-denied-container">
      <h1>Acesso Negado</h1>
      <p>Você não tem permissão para acessar esta página.</p>
      <button onClick={goHome} className="btn btn-primary">Voltar para a Home</button>
    </div>
  );
};

export default AccessDenied;
