// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa o CSS do Bootstrap
import App from './App'; // Importa o componente principal App


// Seleciona o elemento root no HTML
const container = document.getElementById('root');
const root = createRoot(container);

// Renderiza o aplicativo React no elemento root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Função para reportar métricas de desempenho (Web Vitals)
const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
