import React, { useEffect, useState, useContext, useMemo } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import DashboardModal from './DashboardModal';
import DashboardAnalytics from './DashboardAnalytics';
import DashboardFiltersAndButtons from './DashboardFiltersAndButtons';
import ImportDashboardModal from './ImportDashboardModal';
import DashboardTable from './DashboardTable';
import '../../App.css';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Função para buscar todos os dashboards
export const getAllDashboards = async () => {
  const response = await apiClient.get('/api-dashflow/dashboards');
  return response.data;
};

// Função para criar ou atualizar um dashboard
export const createOrUpdateDashboard = async (dashboard) => {
  try {
    const response = await apiClient.post('/api-dashflow/save-dashboard', dashboard);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      throw new Error('Dashboard já existe.');
    }
    throw error;
  }
};

// Função para atualizar um dashboard existente
export const updateDashboard = async (id, dashboard) => {
  const response = await apiClient.put(`/api-dashflow/dashboards/${id}`, dashboard);
  return response.data;
};

// Função para deletar um dashboard
export const deleteDashboard = async (id) => {
  const response = await apiClient.delete(`/api-dashflow/dashboards/${id}`);
  return response.data;
};

// Função para buscar os workspaces
export const getWorkspaces = async () => {
  const response = await apiClient.get('/api-dashflow/workspaces');
  return response.data;
};

// Função para atualizar os workspaces
export const updateWorkspaces = async () => {
  const response = await apiClient.get('/api-dashflow/update-workspaces');
  return response.data;
};

// Função para buscar dashboards por WorkspaceId
export const getDashboardsByWorkspace = async (workspaceId) => {
  const response = await apiClient.get(`/api-dashflow/dashboards/${workspaceId}`);
  return response.data;
};

// Função para atualizar dashboards (com workspaceId)
export const updateDashboards = async (workspaceId) => {
  const response = await apiClient.get(`/api-dashflow/update-dashboards/${workspaceId}`);
  return response.data;
};

// Função para buscar todos os clusters
export const getClusters = async () => {
  const response = await apiClient.get('/api-dashflow/clusters');
  return response.data;
};

// Função para buscar todos os grupos
export const getGroups = async () => {
  const response = await apiClient.get('/api-dashflow/groups');
  return response.data;
};

const ManageDashboards = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dashboards, setDashboards] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    report_name: '',
    cluster: '',
    grupo: '',
    status: '',
    is_default: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const dashboardsPerPage = 20;
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [currentDashboard, setCurrentDashboard] = useState(null);
  const [clusters, setClusters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/access-denied');
    } else {
      fetchDashboards();
    }
  }, [token, navigate]);

  useEffect(() => {
    applyFilters(dashboards, filters);
  }, [dashboards, filters]);

  const fetchDashboards = async () => {
    try {
      const result = await getAllDashboards();
      setDashboards(result);
      setClusters([...new Set(result.map((d) => d.cluster))]);
      setGroups([...new Set(result.map((d) => d.grupo))]);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar todos os dashboards:', error);
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setModalMode('create');
    setShowModal(true);
  };

  const handleEdit = (dashboard) => {
    setCurrentDashboard(dashboard);
    setModalMode('edit');
    setShowModal(true);
  };

  const handleSaveEdit = async (dashboard) => {
    try {
      await updateDashboard(dashboard.pk_report_id, dashboard);
      fetchDashboards();
      setShowModal(false);
    } catch (error) {
      console.error('Erro ao salvar o dashboard:', error);
    }
  };

  const handleSave = async (dashboard) => {
    try {
      if (modalMode === 'create') {
        await createOrUpdateDashboard(dashboard);
      } else {
        await handleSaveEdit(dashboard);
      }
      fetchDashboards();
      setShowModal(false);
    } catch (error) {
      if (error.message === 'Dashboard já existe.') {
        alert('O dashboard já existe. Selecione outro dashboard ou outro Cluster');
      } else {
        console.error('Erro ao salvar o dashboard:', error);
      }
    }
  };

  const handleDelete = async (pk_report_id) => {
    if (window.confirm('Tem certeza que deseja deletar este dashboard?')) {
      try {
        await deleteDashboard(pk_report_id);
        fetchDashboards();
      } catch (error) {
        console.error('Erro ao deletar o dashboard:', error);
      }
    }
  };

  const handleToggleStatus = async (pk_report_id) => {
    try {
      const dashboard = dashboards.find((d) => d.pk_report_id === pk_report_id);
      const updatedDashboard = { ...dashboard, status: !dashboard.status };
      await updateDashboard(pk_report_id, updatedDashboard);
      fetchDashboards();
    } catch (error) {
      console.error('Erro ao atualizar o status do dashboard:', error);
    }
  };

  // Função para ativar/desativar o QeA
  const handleToggleQeA = async (pk_report_id) => {
    try {
      const dashboard = dashboards.find((d) => d.pk_report_id === pk_report_id);
      const updatedDashboard = { ...dashboard, QeA: !dashboard.QeA };
      await updateDashboard(pk_report_id, updatedDashboard);
      fetchDashboards();
    } catch (error) {
      console.error('Erro ao atualizar o QeA do dashboard:', error);
    }
  };

  const applyFilters = (dashboards, filters) => {
    let filtered = dashboards;

    if (filters.report_name) {
      filtered = filtered.filter((d) =>
        d.report_name.toLowerCase().includes(filters.report_name.toLowerCase())
      );
    }

    if (filters.cluster) {
      filtered = filtered.filter((d) =>
        d.cluster.toLowerCase().includes(filters.cluster.toLowerCase())
      );
    }

    if (filters.grupo) {
      filtered = filtered.filter((d) =>
        d.grupo.toLowerCase().includes(filters.grupo.toLowerCase())
      );
    }

    if (filters.status !== '') {
      filtered = filtered.filter((d) => String(d.status) === filters.status);
    }

    setFilteredDashboards(filtered);
  };

  const currentDashboards = useMemo(() => {
    const indexOfLastDashboard = currentPage * dashboardsPerPage;
    return filteredDashboards.slice(0, indexOfLastDashboard);
  }, [currentPage, filteredDashboards]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="manage-dashboards" style={{ backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <h1>Gestão de Dashboards</h1>

      <DashboardAnalytics
        activeDashboardsCount={dashboards.filter((d) => d.status === true).length}
        inactiveDashboardsCount={dashboards.filter((d) => d.status === false).length}
        dashboardData={dashboards}
      />

      <div className="section-divider"></div>

      <DashboardFiltersAndButtons
        handleCreate={handleCreate}
        filters={filters}
        setFilters={setFilters}
        clusters={clusters}
        groups={groups}
        dashboards={dashboards}
        onImport={() => setShowImportModal(true)}
      />

      <div className="section-divider"></div>

      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
          <DashboardTable
            dashboards={currentDashboards}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleToggleStatus={handleToggleStatus}
            handleToggleQeA={handleToggleQeA} // Passando a função aqui
            handleSaveEdit={handleSaveEdit}
          />
        </>
      )}

      <DashboardModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={handleSave}
        dashboard={currentDashboard}
        mode={modalMode}
      />

      <ImportDashboardModal
        show={showImportModal}
        onClose={() => setShowImportModal(false)}
      />
    </div>
  );
};

export default ManageDashboards;
