import React, { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const UserStatsChart = ({ activeUsers, inactiveUsers, adminUsers, activeUsersByCluster }) => {
  const [colors, setColors] = useState({
    activeColor: '#4caf50',
    inactiveColor: '#f44336',
    adminColor: '#9c27b0',
    backgroundColor: '#ffffff',
    textColor: '#333', // Adicionei textColor
  });

  const updateColors = () => {
    const rootStyles = getComputedStyle(document.body);
    setColors({
      activeColor: rootStyles.getPropertyValue('--primary-color').trim() || '#4caf50',
      inactiveColor: rootStyles.getPropertyValue('--danger-color').trim() || '#f44336',
      adminColor: rootStyles.getPropertyValue('--secondary-color').trim() || '#9c27b0',
      backgroundColor: rootStyles.getPropertyValue('--card-background-color').trim() || '#ffffff',
      textColor: rootStyles.getPropertyValue('--text-color').trim() || '#333', // Adicionei textColor
    });
  };

  useEffect(() => {
    updateColors();

    const observer = new MutationObserver(() => {
      updateColors();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => observer.disconnect();
  }, []);

  const clusterLabels = activeUsersByCluster ? Object.keys(activeUsersByCluster) : [];
  const clusterData = activeUsersByCluster ? Object.values(activeUsersByCluster) : [];

  // Data for Vertical Bar Chart
  const barData = {
    labels: ['Usuários Ativos', 'Usuários Inativos'],
    datasets: [
      {
        label: 'Usuários',
        data: [activeUsers, inactiveUsers],
        backgroundColor: [colors.activeColor, colors.inactiveColor],
        borderColor: [colors.activeColor, colors.inactiveColor],
        borderWidth: 1,
        barThickness: 10, 
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: colors.textColor,
        },
      },
      x: {
        ticks: {
          color: colors.textColor,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  // Data for Doughnut Chart (Admin vs Active Users)
  const doughnutData = {
    labels: ['Usuários Administradores', 'Usuários Ativos'],
    datasets: [
      {
        label: 'Proporção de Administradores',
        data: [adminUsers, activeUsers - adminUsers],
        backgroundColor: [colors.adminColor, colors.activeColor],
        borderColor: [colors.adminColor, colors.activeColor],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  // Data for Cluster Bar Chart (Active Users by Cluster)
  const clusterBarData = {
    labels: clusterLabels,
    datasets: [
      {
        label: 'Usuários Ativos por Cluster',
        data: clusterData,
        backgroundColor: colors.activeColor,
        borderColor: colors.activeColor,
        borderWidth: 1,
        barThickness: 10,
      },
    ],
  };

  const clusterBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: colors.textColor,
        },
      },
      x: {
        ticks: {
          color: colors.textColor,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
      <div style={{ width: '30%', height: '250px' }}>
        <Bar data={barData} options={barOptions} />
      </div>
      <div style={{ width: '30%', height: '250px' }}>
        <Doughnut data={doughnutData} options={doughnutOptions} />
      </div>
      <div style={{ width: '30%', height: '250px' }}>
        <Bar data={clusterBarData} options={clusterBarOptions} />
      </div>
    </div>
  );
};

export default UserStatsChart;
