import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { getAllDashboards } from './ManageDashboards';
import { AuthContext } from '../../context/AuthContext';

const EditDashboardModal = ({ show, onHide, dashboard, onSave }) => {
  const { user } = useContext(AuthContext);
  const [localDashboard, setLocalDashboard] = useState({
    pk_report_id: '',
    report_name: '',
    cluster: '',
    grupo: '',
    status: true,
    is_default: false,
  });
  const [clusters, setClusters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newCluster, setNewCluster] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [showNewClusterInput, setShowNewClusterInput] = useState(false);
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);

  useEffect(() => {
    if (dashboard) {
      setLocalDashboard({
        pk_report_id: dashboard.pk_report_id || '',
        report_name: dashboard.report_name || '',
        cluster: dashboard.cluster || '',
        grupo: dashboard.grupo || '',
        status: dashboard.status,
        is_default: dashboard.is_default,
      });
    }
  }, [dashboard]);

  useEffect(() => {
    const fetchDashboardsData = async () => {
      try {
        const data = await getAllDashboards();
        const uniqueClusters = [...new Set(data.map(item => item.cluster))];
        const uniqueGroups = [...new Set(data.map(item => item.grupo))];
        setClusters(uniqueClusters);
        setGroups(uniqueGroups);
      } catch (error) {
        console.error('Erro ao buscar clusters e grupos:', error);
      }
    };

    fetchDashboardsData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalDashboard((prevDashboard) => ({
      ...prevDashboard,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = () => {
    const updatedDashboard = {
      ...localDashboard,
      cluster: showNewClusterInput ? newCluster : localDashboard.cluster,
      grupo: showNewGroupInput ? newGroup : localDashboard.grupo,
    };

    if (!updatedDashboard.pk_report_id || !updatedDashboard.report_name || !updatedDashboard.cluster || !updatedDashboard.grupo) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    onSave(updatedDashboard);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formReportName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="report_name"
              value={localDashboard.report_name}
              onChange={handleChange}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="formCluster">
            <Form.Label>Cluster</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                name="cluster"
                value={localDashboard.cluster}
                onChange={handleChange}
                disabled={showNewClusterInput}
              >
                <option value="">Selecione um Cluster</option>
                {clusters.map((cluster, index) => (
                  <option key={index} value={cluster}>
                    {cluster}
                  </option>
                ))}
              </Form.Control>
              <Button
                variant="outline-secondary"
                onClick={() => setShowNewClusterInput(!showNewClusterInput)}
              >
                +
              </Button>
            </InputGroup>
            {showNewClusterInput && (
              <Form.Control
                type="text"
                placeholder="Nome do Novo Cluster"
                value={newCluster}
                onChange={(e) => setNewCluster(e.target.value)}
              />
            )}
          </Form.Group>

          <Form.Group controlId="formGrupo">
            <Form.Label>Grupo</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                name="grupo"
                value={localDashboard.grupo}
                onChange={handleChange}
                disabled={showNewGroupInput}
              >
                <option value="">Selecione um Grupo</option>
                {groups.map((group, index) => (
                  <option key={index} value={group}>
                    {group}
                  </option>
                ))}
              </Form.Control>
              <Button
                variant="outline-secondary"
                onClick={() => setShowNewGroupInput(!showNewGroupInput)}
              >
                +
              </Button>
            </InputGroup>
            {showNewGroupInput && (
              <Form.Control
                type="text"
                placeholder="Nome do Novo Grupo"
                value={newGroup}
                onChange={(e) => setNewGroup(e.target.value)}
              />
            )}
          </Form.Group>

          <Form.Group controlId="formStatus">
            <Form.Check
              type="checkbox"
              label="Ativo"
              name="status"
              checked={localDashboard.status}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formIsDefault">
            <Form.Check
              type="checkbox"
              label="Principal"
              name="is_default"
              checked={localDashboard.is_default}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar Alterações
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditDashboardModal;
