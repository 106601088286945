import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';

const ManageUserModal = ({ show, onHide }) => {
  const [userInfo, setUserInfo] = useState({
    username: '',
    senhaPadrao: '',
    licenseLimit: 0,
    servicePassword: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isServiceUserPresent, setIsServiceUserPresent] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchCredentials = async () => {
    setIsLoading(true);
    try {
      const credentialsResponse = await axios.get(`${apiUrl}/api-dashflow/credentials`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` },
      });

      const serviceUserResponse = await axios.get(`${apiUrl}/api-dashflow/service-user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` },
      });

      setUserInfo({
        senhaPadrao: credentialsResponse.data.senhaPadrao || 'Senha não definida',
        licenseLimit: credentialsResponse.data.licenseLimit || 0,
        username: serviceUserResponse.data.username || '',
        servicePassword: serviceUserResponse.data.servicePassword || '',
      });

      setIsServiceUserPresent(!!serviceUserResponse.data.username);
    } catch (error) {
      console.error('Erro ao buscar dados:', error.message);
      setError('Erro ao buscar dados: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCredentials = async () => {
    try {
      await axios.post(
        `${apiUrl}/api-dashflow/save-service-user`,
        { username: userInfo.username, password: userInfo.servicePassword },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` } }
      );

      const tokenResponse = await axios.post(
        `${apiUrl}/api-dashflow/generate-service-token`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` } }
      );

      const { refresh_token } = tokenResponse.data;

      if (refresh_token) {
        setSuccessMessage('Credenciais salvas e token gerado com sucesso.');
      } else {
        throw new Error('Falha ao gerar o token. Verifique as credenciais.');
      }
    } catch (error) {
      console.error('Erro ao salvar credenciais ou gerar token:', error.message);
      setError('Erro ao salvar credenciais ou gerar token: ' + error.message);
    }
  };

  const handleLogoutServiceUser = async () => {
    try {
      await axios.post(
        `${apiUrl}/api-dashflow/logout-service-user`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` } }
      );

      setUserInfo((prevState) => ({ ...prevState, username: '', servicePassword: '' }));
      setIsServiceUserPresent(false);
      setSuccessMessage('Usuário de serviço desconectado com sucesso.');
    } catch (error) {
      console.error('Erro ao desconectar usuário de serviço:', error.message);
      setError('Erro ao desconectar usuário de serviço: ' + error.message);
    }
  };

  const handlePasswordChange = async (newPassword) => {
    try {
      await axios.post(
        `${apiUrl}/api-dashflow/credentials`,
        { newPassword },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth-token')}` } }
      );

      setSuccessMessage('Senha padrão alterada com sucesso.');
      setShowPasswordModal(false);
      await fetchCredentials();
    } catch (error) {
      console.error('Erro ao alterar a senha padrão:', error.message);
      setError('Erro ao alterar a senha padrão: ' + error.message);
    }
  };

  useEffect(() => {
    if (show) fetchCredentials();
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Gestão de Credenciais</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            {/* Credenciais Padrão */}
            <div className="d-flex align-items-center justify-content-between">
              <h4>Credenciais Padrão</h4>
              <p>
                <strong>Senha Padrão:</strong> {userInfo.senhaPadrao}
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => setShowPasswordModal(true)}
                >
                  Trocar Senha
                </Button>
              </p>
            </div>
            <hr />
            <div>
              <h4>Licenças</h4>
              <p>
                <strong>Quantidade de Licenças:</strong> {userInfo.licenseLimit}
              </p>
            </div>

            <hr />

            {/* Usuário de Serviço */}
            <div>
              <h4>Usuário de Serviço</h4>
              {isServiceUserPresent ? (
                <div className="d-flex align-items-center justify-content-between">
                  <p>
                    <strong>Usuário:</strong> {userInfo.username}
                  </p>
                  <Button variant="danger" onClick={handleLogoutServiceUser}>
                    Logout
                  </Button>
                </div>
              ) : (
                <>
                  <Form.Group controlId="serviceUsername" className="mt-3">
                    <Form.Label>Usuário</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o usuário de serviço"
                      value={userInfo.username}
                      onChange={(e) =>
                        setUserInfo((prevState) => ({ ...prevState, username: e.target.value }))
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="servicePassword" className="mt-3">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Digite a senha de serviço"
                      value={userInfo.servicePassword}
                      onChange={(e) =>
                        setUserInfo((prevState) => ({ ...prevState, servicePassword: e.target.value }))
                      }
                    />
                  </Form.Group>
                  <Button variant="primary" className="mt-3" onClick={handleSaveCredentials}>
                    Salvar Credenciais
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>

      {/* Modal para Trocar Senha */}
      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Trocar Senha Padrão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              const newPassword = e.target.newPassword.value;
              handlePasswordChange(newPassword);
            }}
          >
            <Form.Group controlId="newPassword">
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control type="password" placeholder="Digite a nova senha" required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Salvar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Modal>
  );
};

export default ManageUserModal;