import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import * as powerbi from 'powerbi-client';
import { AuthContext } from '../../context/AuthContext';
import { Button } from 'react-bootstrap';
import { BsFullscreen, BsPrinter, BsFilter } from 'react-icons/bs';
import loadingGif from '../../assets/Loading.gif';
import { Dropdown } from 'react-bootstrap';


const QEA = () => {
  const { user } = useContext(AuthContext);
  const qnaContainerRef = useRef(null);
  const [dashboards, setDashboards] = useState([]);
  const [filteredDashboard, setFilteredDashboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedGrupo, setSelectedGrupo] = useState('');
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [loadingQnA, setLoadingQnA] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api-dashflow/dashboards`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setDashboards(response.data);
        setFilteredDashboard(response.data[0]); // Selecionar o primeiro dashboard como padrão
      } catch (error) {
        setError('Erro ao carregar os dados');
      } finally {
        setLoading(false);
      }
    };
    fetchDashboards();
  }, [user]);

  useEffect(() => {
    const loadQnA = async () => {
      if (!filteredDashboard || !qnaContainerRef.current) return;

      setLoadingQnA(true);
      setErrorMessage(null);

      try {
        const workspaceId = Array.isArray(filteredDashboard.WorkspaceId)
          ? filteredDashboard.WorkspaceId[0]
          : filteredDashboard.WorkspaceId;
        const datasetId = filteredDashboard.dataset_id;

        // Faz uma solicitação ao backend para obter o Q&A Embed Token
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api-dashflow/qnaEmbedInfo`,
          {
            params: {
              reportId: filteredDashboard.report_id,
              workspaceId: workspaceId,
              datasetId: datasetId,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        const { qnaEmbedToken } = response.data;

        const embedConfiguration = {
          type: 'qna',
          tokenType: powerbi.models.TokenType.Embed,
          accessToken: qnaEmbedToken,
          embedUrl: `https://app.powerbi.com/qnaEmbed?datasetId=${datasetId}`,
          datasetIds: [datasetId],
          viewMode: powerbi.models.QnaMode.Interactive,
        };

        const powerbiService = new powerbi.service.Service(
          powerbi.factories.hpmFactory,
          powerbi.factories.wpmpFactory,
          powerbi.factories.routerFactory
        );

        const qnaContainer = qnaContainerRef.current;
        powerbiService.reset(qnaContainer);
        powerbiService.embed(qnaContainer, embedConfiguration);

        setLoadingQnA(false);
      } catch (error) {
        setErrorMessage('Erro ao carregar o Q&A.');
        console.error('Erro ao carregar o Q&A:', error.message);
        setLoadingQnA(false);
      }
    };

    loadQnA();
  }, [filteredDashboard]);

  const toggleFilter = () => setIsFilterVisible(!isFilterVisible);

  const handleFullScreen = () => {
    const qnaContainer = qnaContainerRef.current;
    if (qnaContainer.requestFullscreen) {
      qnaContainer.requestFullscreen();
    } else if (qnaContainer.webkitRequestFullscreen) {
      qnaContainer.webkitRequestFullscreen();
    } else if (qnaContainer.msRequestFullscreen) {
      qnaContainer.msRequestFullscreen();
    } else if (qnaContainer.mozRequestFullScreen) {
      qnaContainer.mozRequestFullScreen();
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const gruposFiltrados = user.isAdmin
    ? [...new Set(dashboards.map((dashboard) => dashboard.grupo))]
    : [...new Set(dashboards.filter((d) => d.cluster === user.cluster).map((dashboard) => dashboard.grupo))];

  const dashboardsFiltrados = dashboards.filter(
    (dashboard) =>
      (user.isAdmin || dashboard.cluster === user.cluster) &&
      (selectedGrupo ? dashboard.grupo === selectedGrupo : true)
  );

  const handleDashboardSelect = (dashboard) => {
    setSelectedDashboard(dashboard.report_name);
    setFilteredDashboard(dashboard);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--text-color)' }}>
        <img src={loadingGif} alt="Carregando..." />
        <p>Carregando dados...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--danger-color)' }}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="QEA" style={{ minHeight: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <div>
        <div
          className="ctn-button"
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '20px' }}
        >
          <Button onClick={toggleFilter} title="Filtros" variant="outline-primary">
            <BsFilter />
          </Button>
          <Button onClick={handleFullScreen} title="Tela cheia" variant="outline-primary">
            <BsFullscreen />
          </Button>
          <Button onClick={handlePrint} title="Imprimir" variant="outline-primary">
            <BsPrinter />
          </Button>
        </div>

        {isFilterVisible && (
          <div className="filters">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {selectedDashboard || 'Selecione um Grupo e Dashboard'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {gruposFiltrados.map((grupo, index) => (
                  <Dropdown.Item
                    key={index}
                    as="div"
                    onMouseEnter={() => setSelectedGrupo(grupo)}
                    style={{ position: 'relative', padding: '10px', fontWeight: 'bold' }}
                  >
                    {grupo}
                    {selectedGrupo === grupo && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '100%',
                          background: '#fff',
                          border: '1px solid rgba(0,0,0,.125)',
                          padding: '10px',
                          minWidth: '200px',
                          zIndex: '1000',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                      >
                        {dashboardsFiltrados
                          .filter((dashboard) => dashboard.grupo === grupo)
                          .map((dashboard, index) => (
                            <div
                              key={index}
                              style={{
                                padding: '8px',
                                cursor: 'pointer',
                                color: selectedDashboard === dashboard.report_name ? '#007bff' : 'black',
                                background: selectedDashboard === dashboard.report_name ? '#f1f1f1' : 'transparent',
                              }}
                              onClick={() => handleDashboardSelect(dashboard)}
                            >
                              {dashboard.report_name}
                            </div>
                          ))}
                      </div>
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {filteredDashboard ? (
          <div
            id="qnaEmbedContainer"
            ref={qnaContainerRef}
            style={{
              height: 'calc(100vh - 120px)',
              width: '100%',
              backgroundColor: '#f4f4f4',
              borderRadius: '8px',
            }}
          ></div>
        ) : (
          <p style={{ textAlign: 'center', color: 'var(--text-color)' }}>Nenhum dashboard selecionado.</p>
        )}
      </div>
    </div>
  );
};

export default QEA;
