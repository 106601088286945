// frontend/src/components/PageNotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="page-not-found-container">
      <h1>404 - Página Não Encontrada</h1>
      <p>Desculpe, a página que você está procurando não existe.</p>
      <button onClick={goHome} className="btn btn-primary">Voltar para a Home</button>
    </div>
  );
};

export default PageNotFound;
