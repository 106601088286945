import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';

const EditUserModal = ({ show, userId, onClose }) => {
    const { token } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        cluster: '',
        access_level: 2,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get(`/api-dashflow/users/${userId}`, {
                    headers: { 'auth-token': token },
                });
                setFormData({
                    name: response.data.name,
                    email: response.data.email,
                    cluster: response.data.cluster,
                    access_level: response.data.access_level,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user:', error);
                setLoading(false);
            }
        };

        if (userId) {
            fetchUser();
        }
    }, [userId, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.put(`/api-dashflow/users/${userId}`, formData, {
                headers: { 'auth-token': token },
            });
            onClose(); // Fechar o modal após salvar as alterações
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleResetPassword = async () => {
        try {
          await api.put(
            `/api-dashflow/users/${userId}/reset-password`, // Rota correta
            {},
            {
              headers: { 'auth-token': token }, // Certifique-se de que o cabeçalho está correto
            }
          );
          alert('Senha redefinida para a padrão: Hortifrut@123');
        } catch (error) {
          console.error('Error resetting password:', error);
          alert('Erro ao redefinir a senha.');
        }
      };
      

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCluster">
                        <Form.Label>Cluster</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Cluster"
                            value={formData.cluster}
                            onChange={(e) => setFormData({ ...formData, cluster: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formAccessLevel">
                        <Form.Label>Nível de Acesso</Form.Label>
                        <Form.Control
                            as="select"
                            value={formData.access_level}
                            onChange={(e) => setFormData({ ...formData, access_level: e.target.value })}
                        >
                            <option value={1}>Admin</option>
                            <option value={2}>Usuário</option>
                        </Form.Control>
                    </Form.Group>
<p></p>
                    <Button variant="primary" type="submit">
                        Salvar Alterações
                    </Button>
                    <Button 
                        variant="secondary" 
                        onClick={handleResetPassword} 
                        style={{ marginLeft: '10px' }} // Adiciona mais espaço entre os botões
                    >
                        Redefinir Senha
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditUserModal;
