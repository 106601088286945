// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { jwtDecode } from 'jwt-decode'; // Certifique-se de usar a importação correta

export const AuthContext = createContext();

export const socket = io(process.env.REACT_APP_API_URL, { 
  transports: ['websocket'],
  upgrade: false,
  autoConnect: false,
});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('auth-token') || null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('user-email') || null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeAuth = () => {
      if (token && user && Object.keys(user).length > 0) {
        const sessionID = getSessionID();
        socket.connect();
        socket.emit('register', { userId: user.id, sessionID });
      } else {
        clearAuthState();
      }
      setLoading(false);
    };
    initializeAuth();
  }, [token, user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('auth-token', token);
    } else {
      localStorage.removeItem('auth-token');
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      setUserEmail(user.email || null);
    } else {
      localStorage.removeItem('user');
      setUserEmail(null);
    }
  }, [user]);

  useEffect(() => {
    const handleLogout = (message) => {
      alert(message);
      logoutUser();
    };

    socket.on('logout', handleLogout);

    return () => {
      socket.off('logout', handleLogout);
    };
  }, []);

  function getSessionID() {
    let sessionID = localStorage.getItem('sessionID');
    if (!sessionID) {
      sessionID = generateSessionID();
      localStorage.setItem('sessionID', sessionID);
    }
    return sessionID;
  }

  function generateSessionID() {
    return 'session-' + Math.random().toString(36).substr(2, 9);
  }

  const clearAuthState = () => {
    setToken(null);
    setUser(null);
    setUserEmail(null);
    localStorage.removeItem('auth-token');
    localStorage.removeItem('user');
    localStorage.removeItem('user-email');
    localStorage.removeItem('sessionID');
    socket.disconnect();
  };

  const login = (data) => {
    const sessionID = getSessionID();
    console.log("Dados recebidos no login:", data);
    
    if (data.token && data.user) {
      // Converte isAdmin para verificar se o nível de acesso é 1
      const user = {
        ...data.user,
        isAdmin: data.user.access_level === 1, // Verifica se access_level é 1 para determinar se é admin
      };
  
      setToken(data.token);
      setUser(user);
      setUserEmail(user.email || null);
  
      const userId = user.id;
      if (!userId) {
        console.error("Erro: user.id não está definido!");
        return;
      }
      
      socket.connect();
      socket.emit('register', { userId, sessionID });
    } else {
      console.error("Erro ao realizar login: dados do usuário ou token não recebidos.");
    }
  };
  
  
  

  const logoutUser = () => {
    clearAuthState();
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ user, token, userEmail, login, logout: logoutUser, setUserEmail, isAuthenticated: !!token, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
