import React, { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const DashboardAnalytics = ({ activeDashboardsCount, inactiveDashboardsCount, dashboardData }) => {
  const [colors, setColors] = useState({
    activeColor: '#4caf50',
    inactiveColor: '#f44336',
    backgroundColor: '#ffffff',
    textColor: '#333',
  });

  const updateColors = () => {
    const rootStyles = getComputedStyle(document.body);
    setColors({
      activeColor: rootStyles.getPropertyValue('--primary-color').trim() || '#4caf50',
      inactiveColor: rootStyles.getPropertyValue('--danger-color').trim() || '#f44336',
      backgroundColor: rootStyles.getPropertyValue('--card-background-color').trim() || '#ffffff',
      textColor: rootStyles.getPropertyValue('--text-color').trim() || '#333',
    });
  };

  useEffect(() => {
    updateColors();

    const observer = new MutationObserver(() => {
      updateColors();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => observer.disconnect();
  }, []);

  const dashboardNames = dashboardData.map(d => d.report_name);
  const totalViewsPerDashboard = dashboardData.map(d => d.total_views);

  const clusters = [...new Set(dashboardData.map(d => d.cluster))];
  const activePerCluster = clusters.map(cluster =>
    dashboardData.filter(d => d.cluster === cluster && d.status).length
  );

  // Data for Total Views Bar Chart
  const barData = {
    labels: dashboardNames,
    datasets: [
      {
        label: 'Visualizações Totais',
        data: totalViewsPerDashboard,
        backgroundColor: colors.activeColor,
        borderColor: colors.activeColor,
        borderWidth: 1,
        barThickness: 20, 
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: colors.textColor,
        },
      },
      x: {
        ticks: {
          color: colors.textColor,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  // Data for Doughnut Chart (Active vs Inactive Dashboards)
  const doughnutData = {
    labels: ['Dashboards Ativos', 'Dashboards Inativos'],
    datasets: [
      {
        label: 'Proporção de Dashboards',
        data: [activeDashboardsCount, inactiveDashboardsCount],
        backgroundColor: [colors.activeColor, colors.inactiveColor],
        borderColor: [colors.activeColor, colors.inactiveColor],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  // Data for Cluster Bar Chart (Active Dashboards by Cluster)
  const clusterBarData = {
    labels: clusters,
    datasets: [
      {
        label: 'Dashboards Ativos por Cluster',
        data: activePerCluster,
        backgroundColor: colors.activeColor,
        borderColor: colors.activeColor,
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const clusterBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: colors.textColor,
        },
      },
      x: {
        ticks: {
          color: colors.textColor,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.textColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div className="dashboard-analytics">
      <div className="users-info">
        <div className="info-card">
          <div className="title">Dashboards Ativos</div>
          <div className="text-value">{activeDashboardsCount}</div>
        </div>
        <div className="info-card">
          <div className="title">Dashboards Inativos</div>
          <div className="text-value">{inactiveDashboardsCount}</div>
        </div>
      </div>

      <div className="chart-container centered-charts">
        <div className="chart-wrapper">
          <Bar data={barData} options={barOptions} />
        </div>

        <div className="chart-wrapper">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>

        <div className="chart-wrapper">
          <Bar data={clusterBarData} options={clusterBarOptions} />
        </div>
      </div>
    </div>
  );
};

export default DashboardAnalytics;
