// src/page/components/AccessTokenDisplay.js
import React, { useState } from 'react';

const AccessTokenDisplay = ({ token }) => {
    const [isTokenVisible, setIsTokenVisible] = useState(false);

    const toggleTokenVisibility = () => {
        setIsTokenVisible(!isTokenVisible);
    };

    return (
        <div className="ctn-token-display">
            <p>
                <i className="bi bi-lock"></i>
                <span> Token de acesso: </span>
                <span className="token-display">
                    {isTokenVisible ? token : '*****************'}
                </span>
            </p>
            <button 
                onClick={toggleTokenVisibility}
                className="toggle-token-btn"
                aria-label={isTokenVisible ? "Ocultar token" : "Mostrar token"}
            >
                {isTokenVisible ? (
                    <i className="bi bi-eye-slash"></i>
                ) : (
                    <i className="bi bi-eye"></i>
                )}
            </button>
        </div>
    );
};

export default AccessTokenDisplay;
