import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, InputGroup, Spinner, Alert } from 'react-bootstrap';
import { getWorkspaces, getDashboardsByWorkspace, updateWorkspaces, updateDashboards, getClusters, getGroups, apiClient } from './ManageDashboards';
import { FaSyncAlt } from 'react-icons/fa';

const DashboardModal = ({ show, onHide, onSave, dashboard, mode }) => {
  const [localDashboard, setLocalDashboard] = useState({
    report_id: '',
    report_name: '',
    cluster: '',
    grupo: '',
    status: true,
    is_default: false,
  });
  const [workspaces, setWorkspaces] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(false);
  const [loadingDashboards, setLoadingDashboards] = useState(false);
  const [error, setError] = useState(null);
  const [showNewClusterInput, setShowNewClusterInput] = useState(false);
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);
  const [newCluster, setNewCluster] = useState('');
  const [newGroup, setNewGroup] = useState('');
  // Limpa o estado quando o modal é fechado ou aberto
  const resetState = () => {
    setLocalDashboard({
      report_id: '',
      report_name: '',
      cluster: '',
      grupo: '',
      status: true,
      is_default: false,
    });
    setWorkspaces([]);
    setClusters([]);
    setGroups([]);
    setFilteredDashboards([]);
    setSelectedWorkspace('');
    setError(null);
    setNewCluster('');
    setNewGroup('');
    setShowNewClusterInput(false);
    setShowNewGroupInput(false);
  };

  useEffect(() => {
    if (show) {
      resetState();

      if (dashboard) {
        setLocalDashboard(dashboard);
        setSelectedWorkspace(dashboard.WorkspaceId || '');
      }

      // Carregar workspaces, clusters e grupos ao abrir o modal
      const fetchWorkspacesAndData = async () => {
        try {
          setLoadingWorkspaces(true);
          const workspacesData = await getWorkspaces();
          setWorkspaces(Array.isArray(workspacesData) ? workspacesData : []);

          const clustersData = await getClusters();
          setClusters(Array.isArray(clustersData) ? clustersData.map(c => c.cluster) : []);

          const groupsData = await getGroups();
          setGroups(Array.isArray(groupsData) ? groupsData.map(g => g.grupo) : []);
        } catch (error) {
          setError('Erro ao buscar os dados');
          console.error('Erro ao buscar os dados:', error);
        } finally {
          setLoadingWorkspaces(false);
        }
      };

      fetchWorkspacesAndData();
    } else {
      resetState();
    }
  }, [show, dashboard]);

  // Função para atualizar os workspaces quando o botão "Atualizar" é clicado
  const handleUpdateWorkspaces = useCallback(async () => {
    try {
      setLoadingWorkspaces(true);
      await updateWorkspaces();
      const workspacesData = await getWorkspaces();
      setWorkspaces(Array.isArray(workspacesData) ? workspacesData : []);
      alert('Workspaces atualizados com sucesso!');
    } catch (error) {
      setError('Erro ao atualizar os workspaces');
      console.error('Erro ao atualizar os workspaces:', error);
    } finally {
      setLoadingWorkspaces(false);
    }
  }, []);

  // Função para carregar dashboards após selecionar um workspace
  useEffect(() => {
    if (selectedWorkspace) {
      const fetchDashboards = async () => {
        setLoadingDashboards(true);
        setError(null);
        try {
          // Chama a rota que busca os dashboards por WorkspaceId
          const dashboards = await getDashboardsByWorkspace(selectedWorkspace);
          setFilteredDashboards(dashboards);
        } catch (error) {
          setError('Erro ao buscar dashboards para o workspace selecionado');
          console.error('Erro ao buscar dashboards para o workspace selecionado:', error);
        } finally {
          setLoadingDashboards(false);
        }
      };
      fetchDashboards();
    } else {
      setFilteredDashboards([]);
    }
  }, [selectedWorkspace]);

  // Função para atualizar os dashboards do workspace selecionado
  const handleUpdateDashboards = useCallback(async () => {
    try {
      if (!selectedWorkspace) {
        alert('Por favor, selecione um workspace primeiro.');
        return;
      }
      setLoadingDashboards(true);
      await updateDashboards(selectedWorkspace);
      const dashboards = await getDashboardsByWorkspace(selectedWorkspace);
      setFilteredDashboards(dashboards);
      alert('Dashboards atualizados com sucesso!');
    } catch (error) {
      setError('Erro ao atualizar os dashboards');
      console.error('Erro ao atualizar os dashboards:', error);
    } finally {
      setLoadingDashboards(false);
    }
  }, [selectedWorkspace]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setLocalDashboard((prevDashboard) => {
      const updatedDashboard = {
        ...prevDashboard,
        [name]: type === 'checkbox' ? checked : value,
      };

      if (name === 'report_id') {
        // Encontrar o dashboard selecionado
        const selectedDashboard = filteredDashboards.find(
          (d) => d.DashboardId === value
        );
        if (selectedDashboard) {
          updatedDashboard.report_name = selectedDashboard.DashboardName;
        }
      }

      return updatedDashboard;
    });

    if (name === 'workspaceId') {
      setSelectedWorkspace(value);
    }
  };

  const handleSave = async () => {
    if (showNewClusterInput) {
      localDashboard.cluster = newCluster;
    }
    if (showNewGroupInput) {
      localDashboard.grupo = newGroup;
    }

    if (!localDashboard.report_id || !selectedWorkspace) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    const dashboardData = {
      report_id: localDashboard.report_id,
      report_name: localDashboard.report_name,
      cluster: localDashboard.cluster,
      grupo: localDashboard.grupo,
      status: localDashboard.status,
      is_default: localDashboard.is_default,
      workspaceid: selectedWorkspace,
    };

    try {
      await onSave(dashboardData);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const confirmUpdate = window.confirm('Este dashboard já existe. Deseja atualizar os dados existentes?');
        if (confirmUpdate) {
          await onSave(dashboardData, true); // true pode indicar que é um update
        } else {
          alert('Operação cancelada.');
        }
      } else {
        console.error('Erro ao salvar o dashboard:', error);
        alert('Erro ao salvar o dashboard. Verifique o console para mais detalhes.');
      }
    }
  };




  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Criar Dashboard' : 'Editar Dashboard'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Workspace */}
          <Form.Group controlId="formWorkspace">
            <Form.Label>Workspace</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                name="workspaceId"
                value={selectedWorkspace}
                onChange={(e) => {
                  handleChange(e);
                  setLocalDashboard((prevDashboard) => ({
                    ...prevDashboard,
                    report_id: '',
                    report_name: '',
                  }));
                }}
                disabled={loadingWorkspaces}
              >
                <option value="">Selecione um Workspace</option>
                {workspaces.map((workspace) => (
                  <option key={workspace.WorkspaceId} value={workspace.WorkspaceId}>
                    {workspace.WorkspaceName}
                  </option>
                ))}
              </Form.Control>

              {/* Botão de Atualizar Workspaces */}
              <Button variant="outline-secondary" onClick={handleUpdateWorkspaces} disabled={loadingWorkspaces}>
                {loadingWorkspaces ? <Spinner as="span" animation="border" size="sm" /> : <FaSyncAlt />}
              </Button>
            </InputGroup>
            {error && <Alert variant="danger">{error}</Alert>}
          </Form.Group>

          {/* Dashboards */}
          {selectedWorkspace && (
            <Form.Group controlId="formDashboard">
              <Form.Label>Dashboard</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="report_id"
                  value={localDashboard.report_id}
                  onChange={handleChange}
                  disabled={loadingDashboards}
                >
                  <option value="">Selecione um Dashboard</option>
                  {filteredDashboards.map((dashboard) => (
                    <option key={dashboard.DashboardId} value={dashboard.DashboardId}>
                      {dashboard.DashboardName}
                    </option>
                  ))}
                </Form.Control>

                {/* Botão de Atualizar Dashboards */}
                <Button
                  variant="outline-secondary"
                  onClick={handleUpdateDashboards}
                  disabled={loadingDashboards}
                >
                  {loadingDashboards ? <Spinner as="span" animation="border" size="sm" /> : <FaSyncAlt />}
                </Button>
              </InputGroup>
            </Form.Group>
          )}

          {/* Clusters */}
          <Form.Group controlId="formCluster">
            <Form.Label>Cluster</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                name="cluster"
                value={localDashboard.cluster}
                onChange={handleChange}
                disabled={showNewClusterInput}
              >
                <option value="">Selecione um Cluster</option>
                {clusters.map((cluster, index) => (
                  <option key={index} value={cluster}>
                    {cluster}
                  </option>
                ))}
              </Form.Control>
              <Button
                variant="outline-secondary"
                onClick={() => setShowNewClusterInput(!showNewClusterInput)}
              >
                +
              </Button>
            </InputGroup>
            {showNewClusterInput && (
              <Form.Control
                type="text"
                placeholder="Nome do Novo Cluster"
                value={newCluster}
                onChange={(e) => setNewCluster(e.target.value)}
              />
            )}
          </Form.Group>

          {/* Grupos */}
          <Form.Group controlId="formGrupo">
            <Form.Label>Grupo</Form.Label>
            <InputGroup>
              <Form.Control
                as="select"
                name="grupo"
                value={localDashboard.grupo}
                onChange={handleChange}
                disabled={showNewGroupInput}
              >
                <option value="">Selecione um Grupo</option>
                {groups.map((group, index) => (
                  <option key={index} value={group}>
                    {group}
                  </option>
                ))}
              </Form.Control>
              <Button
                variant="outline-secondary"
                onClick={() => setShowNewGroupInput(!showNewGroupInput)}
              >
                +
              </Button>
            </InputGroup>
            {showNewGroupInput && (
              <Form.Control
                type="text"
                placeholder="Nome do Novo Grupo"
                value={newGroup}
                onChange={(e) => setNewGroup(e.target.value)}
              />
            )}
          </Form.Group>

          {/* Status */}
          <Form.Group controlId="formStatus">
            <Form.Check
              type="checkbox"
              label="Ativo"
              name="status"
              checked={localDashboard.status}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Principal */}
          <Form.Group controlId="formIsDefault">
            <Form.Check
              type="checkbox"
              label="Principal"
              name="is_default"
              checked={localDashboard.is_default}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardModal;
