// frontend/src/components/Footer.js
import React from 'react';
import '../../App.css';
import imlogo from "../../assets/logoinsights.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a href="https://www.insightsmachine.com.br" target="_blank" rel="noopener noreferrer">
          <img src={imlogo} alt="Logo Insights Machine" className="footer-logo" />
        </a>
        <p className="footer-text">
          @Inovação e tecnologia por Insights Machine
        </p>
      </div>
    </footer>
  );
};

export default Footer;
