import React, { useState, useEffect } from 'react';
import { Button, InputGroup, Form } from 'react-bootstrap';
import ExportDashboard from './ExportDashboard';
import ImportDashboardModal from './ImportDashboardModal';

const DashboardFiltersAndButtons = ({
  handleCreate,
  filters,
  setFilters,
  clusters = [],
  groups = [],
  dashboards,
}) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [themeColors, setThemeColors] = useState({
    primaryColor: '#54412F',
    primaryHoverColor: '#3e2f22',
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };

  const updateThemeColors = () => {
    const rootStyles = getComputedStyle(document.body);
    setThemeColors({
      primaryColor: rootStyles.getPropertyValue('--primary-color').trim() || '#54412F',
      primaryHoverColor: rootStyles.getPropertyValue('--primary-hover-color').trim() || '#3e2f22',
    });
  };

  useEffect(() => {
    updateThemeColors();

    const observer = new MutationObserver(() => {
      updateThemeColors();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="filters-buttons-container d-flex justify-content-between align-items-center">
      <div className="buttons-container d-flex">
        <Button
          onClick={handleCreate}
          className="create-button mr-2"
          style={{
            backgroundColor: themeColors.primaryColor,
            borderColor: themeColors.primaryColor,
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = themeColors.primaryHoverColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = themeColors.primaryColor)}
        >
          Criar Dashboard
        </Button>
        <Button
          onClick={handleImportClick}
          className="import-button mr-2"
          style={{
            backgroundColor: themeColors.primaryColor,
            borderColor: themeColors.primaryColor,
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = themeColors.primaryHoverColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = themeColors.primaryColor)}
        >
          Importar Dashboard
        </Button>
        <ExportDashboard dashboards={dashboards} />
      </div>
      <div className="filters-container d-flex">
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Nome"
            name="report_name"
            value={filters.report_name}
            onChange={handleFilterChange}
            className="mr-sm-2"
            style={{
              borderColor: themeColors.primaryColor,
              color: themeColors.primaryColor,
            }}
          />
          <Form.Select
            name="cluster"
            value={filters.cluster}
            onChange={handleFilterChange}
            className="mr-sm-2"
            style={{
              borderColor: themeColors.primaryColor,
              color: themeColors.primaryColor,
            }}
          >
            <option value="">Cluster</option>
            {clusters.map((cluster, index) => (
              <option key={index} value={cluster}>
                {cluster}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            name="grupo"
            value={filters.grupo}
            onChange={handleFilterChange}
            className="mr-sm-2"
            style={{
              borderColor: themeColors.primaryColor,
              color: themeColors.primaryColor,
            }}
          >
            <option value="">Grupo</option>
            {groups.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            className="mr-sm-2"
            style={{
              borderColor: themeColors.primaryColor,
              color: themeColors.primaryColor,
            }}
          >
            <option value="">Status</option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </Form.Select>
        </InputGroup>
      </div>

      <ImportDashboardModal show={showImportModal} onClose={handleCloseImportModal} />
    </div>
  );
};

export default DashboardFiltersAndButtons;
