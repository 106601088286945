import React, { useState } from 'react';
import { Button, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTrashAlt, faUserCheck, faUserSlash, faStar, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import EditDashboardModal from './EditDashboardModal';

const DashboardTable = ({ dashboards, handleEdit, handleDelete, handleToggleStatus, handleToggleQeA, handleSaveEdit }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dashboardToToggle, setDashboardToToggle] = useState(null);

  const handleEditClick = (dashboard) => {
    setCurrentDashboard(dashboard);
    setShowEditModal(true);
  };

  const handleSave = (updatedDashboard) => {
    handleSaveEdit(updatedDashboard);
    setShowEditModal(false);
  };

  const confirmToggleStatus = (dashboard) => {
    setDashboardToToggle(dashboard);
    setShowConfirmModal(true);
  };

  const handleConfirmToggle = () => {
    handleToggleStatus(dashboardToToggle.pk_report_id);
    setShowConfirmModal(false);
  };

  return (
    <>
      <Table striped bordered hover className="ctn-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Nome</th>
            <th>Cluster</th>
            <th>Grupo</th>
            <th>Visualizações Totais</th>
            <th>Usuários Únicos</th>
            <th>Página Mais Acessada</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {dashboards.map((dashboard) => (
            <tr key={dashboard.pk_report_id}>
              <td>{dashboard.status ? 'Ativo' : 'Inativo'}</td>
              <td>
                {dashboard.report_name}{' '}
                {dashboard.is_default && (
                  <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
                )}
              </td>
              <td>{dashboard.cluster}</td>
              <td>{dashboard.grupo}</td>
              <td>{dashboard.total_views}</td>
              <td>{dashboard.unique_users}</td>
              <td>{dashboard.top_page}</td>
              <td className="ctn-button">
                {/* Editar */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-edit">Editar Dashboard</Tooltip>}
                >
                  <Button
                    className="btn-edit"
                    onClick={() => handleEditClick(dashboard)}
                    style={{
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',
                      border: 'none',
                      marginRight: '5px',
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </Button>
                </OverlayTrigger>

                {/* Excluir */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-delete">Excluir Dashboard</Tooltip>}
                >
                  <Button
                    className="btn-trash"
                    onClick={() => handleDelete(dashboard.pk_report_id)}
                    style={{
                      backgroundColor: 'var(--danger-color)',
                      color: 'white',
                      border: 'none',
                      marginRight: '5px',
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </OverlayTrigger>

                {/* Ativar/Desativar */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-status">{dashboard.status ? 'Desativar' : 'Ativar'} Dashboard</Tooltip>}
                >
                  <Button
                    className="btn-toggle"
                    onClick={() => confirmToggleStatus(dashboard)}
                    style={{
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',
                      border: 'none',
                      marginRight: '5px',
                    }}
                  >
                    <FontAwesomeIcon icon={dashboard.status ? faUserSlash : faUserCheck} />
                  </Button>
                </OverlayTrigger>

                {/* Ativar/Desativar QeA */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-qea">{dashboard.QeA ? 'Desativar QeA' : 'Ativar QeA'}</Tooltip>}
                >
                  <Button
                    className="btn-qea"
                    onClick={() => handleToggleQeA(dashboard.pk_report_id, !dashboard.QeA)}
                    style={{
                      backgroundColor: dashboard.QeA ? 'var(--primary-color)' : 'var(--secondary-color)',
                      color: 'white',
                      border: 'none',
                    }}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {currentDashboard && (
        <EditDashboardModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          dashboard={currentDashboard}
          onSave={handleSave}
        />
      )}

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja {dashboardToToggle?.status ? 'inativar' : 'ativar'} o dashboard?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleConfirmToggle}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardTable;
