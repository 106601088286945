import React, { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import AccessTokenDisplay from '../components/AccessTokenDisplay';

const ApiUsersModal = ({ show, onHide }) => {
  // Estado para controlar a expansão das seções
  const [isUsersExpanded, setIsUsersExpanded] = useState(false);
  const [isLogsExpanded, setIsLogsExpanded] = useState(false);
  const [isReportsExpanded, setIsReportsExpanded] = useState(false); // Novo estado para Dashboards Cadastrados

  // Funções para alternar a expansão
  const toggleUsersExpansion = () => setIsUsersExpanded(!isUsersExpanded);
  const toggleLogsExpansion = () => setIsLogsExpanded(!isLogsExpanded);
  const toggleReportsExpansion = () => setIsReportsExpanded(!isReportsExpanded); // Nova função

  return (
    <Modal show={show} onHide={onHide} size="lg" style={{ height: "860px" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#475569" }}>API de Usuários</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto" }}>
        <h4>API de Usuários</h4>
        <AccessTokenDisplay token={process.env.REACT_APP_API_TOKEN} />
        <p>
          Abaixo, você pode visualizar a documentação das APIs possíveis para fazer a integração e coleta de dados dos usuários no formato JSON.
        </p>
        <hr />
        <div>
          <header onClick={toggleUsersExpansion} style={{ cursor: 'pointer', background: "#54412F", color: "white" }}>
            <i
              className={`bi ${isUsersExpanded ? 'bi-caret-down-fill' : 'bi-caret-right-fill'}`}
              style={{ marginRight: '8px' }}
            ></i>
            <h5 style={{ display: 'inline' }}>Usuários Cadastrados</h5>
          </header>
          <hr />
          {isUsersExpanded && (
            <div>
              <h5 className="step-title">Requisição</h5>
              <ul>
                <li>Método: <code>GET</code></li>
                <li>Rota: <code>/api-dashflow/users</code></li>
              </ul>
              <h5 className="step-title">Headers</h5>
              <p>Você precisará enviar o seguinte header HTTP para se autenticar:</p>
              <ul>
                <li>
                  X-Api-Token: <code>&lt;api-token&gt;</code>
                  <Button variant="outline-danger" size="sm" className="px-1 py-0 ml-1" title="Copiar token">
                    <i className="bi bi-clipboard"></i>
                  </Button>
                </li>
              </ul>
              <h5 className="step-title">Exemplo da requisição:</h5>
              <p className="p-2 rounded bg-dark text-light">
                GET /api-dashflow/users
              </p>
              <h5 className="step-title">Resposta</h5>
              <p>
                A resposta esperada é a de sucesso, ou seja, um status code 200 (OK) e os dados no formato JSON.
              </p>
              <Alert variant="success">
                <h6 className="step-title">Sucesso</h6>
                <ul>
                  <li>Status Code: 200</li>
                  <li>
                    Corpo:
                    <pre className="code">
                      {`{
  "total_users": 209,
  "users": [
    {
      "id": "6515",
      "name": "Abel Braga",
      "email": "abel.braga@email.com",
      "cluster": "Padrão",
      "status": "1",
      "is_admin": "false",
      "created_at": "2023-06-06 16:28:51.000",
    },
    ...
  ]
}`}
                    </pre>
                  </li>
                </ul>
              </Alert>
              <Alert variant="danger">
                <h6 className="step-title">Erro de Autenticação</h6>
                <p>
                  Se esse erro aparecer, é provável que você tenha esquecido de fornecer o token, ou tenha fornecido um token errado.
                </p>
                <ul>
                  <li>Status Code: 401</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Unauthorized access, invalid api token or it was not provided" }`}</code>
                  </li>
                </ul>
                <hr />
                <h6 className="step-title">Erro no Servidor</h6>
                <p>
                  Se esse erro aparecer, significa que existe algum problema no sistema, e o suporte precisa ser contatado.
                </p>
                <ul>
                  <li>Status Code: 500/502</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Internal Server Error" }`}</code>
                  </li>
                </ul>
              </Alert>
              <Button variant="outline-secondary" size="sm" onClick={onHide}>
                <i className="bi bi-x-circle"></i> Fechar
              </Button>
              <hr />
            </div>
          )}
        </div>
        <div>
          <header onClick={toggleLogsExpansion} style={{ cursor: 'pointer', background: "#54412F", color: "white" }}>
            <i
              className={`bi ${isLogsExpanded ? 'bi-caret-down-fill' : 'bi-caret-right-fill'}`}
              style={{ marginRight: '8px' }}
            ></i>
            <h5 style={{ display: 'inline' }}>Logs Agrupados</h5>
          </header>
          {isLogsExpanded && (
            <div>
              <p>Essa rota irá retornar todos os tipos de logs. Sendo eles:</p>
              <ul>
                <li>Logins</li>
                <li>Alterações de Registros</li>
                <li>Visualizações de Dashboards</li>
                <li>Visualizações de Páginas de Dashboards</li>
              </ul>
              <h5 className="step-title">Requisição</h5>
              <ul>
                <li>Método: <code>GET</code></li>
                <li>Rota: <code>/api-dashflowlogs/grouped</code></li>
              </ul>
              <h5 className="step-title">Headers</h5>
              <p>Você precisará enviar o seguinte header HTTP para se autenticar:</p>
              <ul>
                <li>
                  X-Api-Token: <code>&lt;api-token&gt;</code>
                  <Button variant="outline-danger" size="sm" className="px-1 py-0 ml-1" title="Copiar token">
                    <i className="bi bi-clipboard"></i>
                  </Button>
                </li>
              </ul>
              <h5 className="step-title">Exemplo da requisição:</h5>
              <p className="p-2 rounded bg-dark text-light">
                GET /api-dashflowlogs/grouped
              </p>
              <h5 className="step-title">Resposta</h5>
              <p>
                A resposta esperada é a de sucesso, ou seja, um status code 200 (OK) e os dados no formato JSON.
              </p>
              <Alert variant="success">
                <h6 className="step-title">Sucesso</h6>
                <ul>
                  <li>Status Code: 200</li>
                  <li>
                    Corpo:
                    <pre className="code">
                      {`[
  {
    "log_type": "Dashboard Access",
    "log_id": "f571e816-9efe-45c8-8c3c-9f33b4f03dd7.Premium",
    "log_date": "2023-09-14 16:57:58.000",
    "log_user": 5691,
    "log_name": "Acompanhamento Ame",
    "log_second_name": null,
    "row_id": 138
  }
  ...
]`}
                    </pre>
                  </li>
                </ul>
              </Alert>
              <Alert variant="danger">
                <h6 className="step-title">Erro de Autenticação</h6>
                <p>
                  Se esse erro aparecer, é provável que você tenha esquecido de fornecer o token, ou tenha fornecido um token errado.
                </p>
                <ul>
                  <li>Status Code: 401</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Unauthorized access, invalid api token or it was not provided" }`}</code>
                  </li>
                </ul>
                <hr />
                <h6 className="step-title">Erro no Servidor</h6>
                <p>
                  Se esse erro aparecer, significa que existe algum problema no sistema, e o suporte precisa ser contatado.
                </p>
                <ul>
                  <li>Status Code: 500/502</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Internal Server Error" }`}</code>
                  </li>
                </ul>
              </Alert>
              <Button variant="outline-secondary" size="sm" onClick={onHide}>
                <i className="bi bi-x-circle"></i> Fechar
              </Button>
            </div>
          )}
        </div>
        <div>
          <hr />
          <header onClick={toggleReportsExpansion} style={{ cursor: 'pointer', background: "#54412F", color: "white" }}>
            <i
              className={`bi ${isReportsExpanded ? 'bi-caret-down-fill' : 'bi-caret-right-fill'}`}
              style={{ marginRight: '8px' }}
            ></i>
            <h5 style={{ display: 'inline' }}>Dashboards Cadastrados</h5>
          </header>
          {isReportsExpanded && (
            <div>
              <h5 className="step-title">Requisição</h5>
              <ul>
                <li>Método: <code>GET</code></li>
                <li>Rota: <code>/api-dashflowreports</code></li>
              </ul>
              <h5 className="step-title">Headers</h5>
              <p>Você precisará enviar o seguinte header HTTP para se autenticar:</p>
              <ul>
                <li>
                  X-Api-Token: <code>&lt;api-token&gt;</code>
                  <Button variant="outline-danger" size="sm" className="px-1 py-0 ml-1" title="Copiar token">
                    <i className="bi bi-clipboard"></i>
                  </Button>
                </li>
              </ul>
              <h5 className="step-title">Exemplo da requisição:</h5>
              <p className="p-2 rounded bg-dark text-light">
                GET /api-dashflowreports
              </p>
              <h5 className="step-title">Resposta</h5>
              <p>
                A resposta esperada é a de sucesso, ou seja, um status code 200 (OK) e os dados no formato JSON.
              </p>
              <Alert variant="success">
                <h6 className="step-title">Sucesso</h6>
                <ul>
                  <li>Status Code: 200</li>
                  <li>
                    Corpo:
                    <pre className="code">
                      {`{
  "total_reports": "35",
  "reports": [
    {
      "pk_report_id": "4b22499f-995c-4e99-a999-87a7c53d5ae4.Premium",
      "report_id": "4b22499f-995c-4e99-a999-87a7c53d5ae4",
      "report_name": "Report do Exemplo",
      "report_cluster": "Premium",
      "status": 1,
      "is_default": false,
      "views": 13,
      "viewers": 5,
      "created_at": "2023-05-25 15:41:32",
    },
    ...
  ]
}`}
                    </pre>
                  </li>
                </ul>
              </Alert>
              <Alert variant="danger">
                <h6 className="step-title">Erro de Autenticação</h6>
                <p>
                  Se esse erro aparecer, é provável que você tenha esquecido de fornecer o token, ou tenha fornecido um token errado.
                </p>
                <ul>
                  <li>Status Code: 401</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Unauthorized access, invalid api token or it was not provided" }`}</code>
                  </li>
                </ul>
                <hr />
                <h6 className="step-title">Erro no Servidor</h6>
                <p>
                  Se esse erro aparecer, significa que existe algum problema no sistema, e o suporte precisa ser contatado.
                </p>
                <ul>
                  <li>Status Code: 500/502</li>
                  <li>
                    Corpo:
                    <code> {`{ "message": "Internal Server Error" }`}</code>
                  </li>
                </ul>
              </Alert>
              <Button variant="outline-secondary" size="sm" onClick={onHide}>
                <i className="bi bi-x-circle"></i> Fechar
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApiUsersModal;
