import React from 'react';
import { Button } from 'react-bootstrap';

const ExportDashboard = ({ dashboards }) => {
  const exportDashboardsToCSV = () => {
    if (!dashboards || !Array.isArray(dashboards)) {
      console.error('Dashboards data is not available or invalid.');
      return;
    }

    const headers = [
      'Report Name',
      'Cluster',
      'Grupo',
      'Status',
      'Is Default',
      'Top Page',
      'Total Views',
      'Unique Users',
      'Report Id',
    ];

    const csvData = [
      headers.join(','),
      ...dashboards.map((dashboard) =>
        [
          dashboard.report_name || 'N/A',
          dashboard.cluster || 'N/A',
          dashboard.grupo || 'N/A',
          dashboard.status ? 'Active' : 'Inactive',
          dashboard.is_default ? 'Yes' : 'No',
          dashboard.top_page || 'N/A',
          dashboard.total_views || 0,
          dashboard.unique_users || 0,
          dashboard.pk_report_id || 'N/A',
        ].join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'dashboards.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button onClick={exportDashboardsToCSV} className="btn-export-dashboard">
      Exportar Dashboards
    </Button>
  );
};

export default ExportDashboard;
