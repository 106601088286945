import React, { useState, useRef, useContext, useEffect } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import loadingGif from '../../assets/Loading.gif';
import Papa from 'papaparse';

const ImportCSVModal = ({ show, onClose }) => {
    const { token } = useContext(AuthContext);
    const [fileName, setFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [showLongProcessWarning, setShowLongProcessWarning] = useState(false); // Estado para o aviso
    const fileInputRef = useRef(null);
    const longProcessTimer = useRef(null);  // Timer para a verificação demorada

    useEffect(() => {
        // Quando o modal é fechado, garantir que o aviso seja removido
        return () => clearTimeout(longProcessTimer.current);
    }, []);

    const handleFileClick = () => {
        setError('');
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csv')) {
                setFileName(file.name);
                setFilePath(event.target.value);
                setError('');
            } else {
                setFileName('');
                setFilePath('');
                setError('Por favor, selecione um arquivo CSV.');
            }
        }
    };

    const handleCheckEmails = async () => {
        setLoading(true);
        setError('');
        setShowLongProcessWarning(false); // Reseta o popup de aviso

        const file = fileInputRef.current.files[0];
        if (!file) {
            setError('Por favor, selecione um arquivo CSV válido.');
            setLoading(false);
            return;
        }

        // Exibir o popup após 8 segundos
        longProcessTimer.current = setTimeout(() => {
            setShowLongProcessWarning(true);
        }, 8000); // 8 segundos

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (result) => {
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('data', JSON.stringify(result.data));

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api-dashflow/sheet-users`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    setResults({
                        ...response.data,
                        data: result.data,
                    });

                } catch (error) {
                    console.error('Erro ao verificar emails:', error);
                    setError('Erro ao verificar emails no banco de dados.');
                } finally {
                    clearTimeout(longProcessTimer.current);  // Limpa o timer ao concluir
                    setLoading(false);
                    setShowLongProcessWarning(false); // Fechar o aviso de processo longo
                }
            }
        });
    };

    const handleSaveEmails = async () => {
        if (!results) {
            setError('Por favor, verifique os emails antes de salvar.');
            return;
        }

        setLoading(true);

        try {
            if (!results.data) {
                throw new Error("Dados do CSV não estão disponíveis.");
            }

            const usersToUpdate = results.emailsToUpdate.map(email => {
                const user = results.data.find(u => u.Email === email);
                return {
                    name: user?.Nome || '',
                    email: user?.Email || '',
                    cluster: user?.Cluster || '',
                    status: user?.Status || '',
                    access_level: user?.['Nível de Acesso'] || ''
                };
            });

            const usersToAdd = results.emailsToAdd.map(email => {
                const user = results.data.find(u => u.Email === email);
                return {
                    name: user?.Nome || '',
                    email: user?.Email || '',
                    cluster: user?.Cluster || '',
                    status: user?.Status || '',
                    access_level: user?.['Nível de Acesso'] || ''
                };
            });

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api-dashflow/save-users`, {
                usersToUpdate,
                usersToAdd
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.status === 200) {
                alert('Usuários salvos com sucesso!');
                onClose();
            } else {
                setError('Erro ao salvar os dados no banco de dados.');
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            setError('Erro ao salvar os dados no banco de dados.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Importar Planilha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".csv"
                    />
                    <Button variant="primary" onClick={handleFileClick} disabled={loading}>
                        Insira o CSV
                    </Button>
                    {fileName && (
                        <div style={{ marginTop: '10px' }}>
                            <p><strong>Nome do arquivo:</strong> {fileName}</p>
                            <p><strong>Caminho do arquivo:</strong> {filePath}</p>
                            <Button variant="secondary" onClick={handleCheckEmails} disabled={loading}>
                                Verificar Emails
                            </Button>
                        </div>
                    )}
                    {loading && (
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <img src={loadingGif} alt="Loading..." style={{ width: '160px', height: '90px' }} />
                        </div>
                    )}
                    {results && (
                        <div style={{ marginTop: '10px' }}>
                            <p><strong>Emails cadastrados (serão atualizados):</strong> {results.existingEmailsCount}</p>
                            <p><strong>Emails novos (serão adicionados):</strong> {results.newEmailsCount}</p>
                            <p><strong>Usuários a serem ativados:</strong> {results.activateCount}</p>
                            <p><strong>Usuários a serem inativados:</strong> {results.inactivateCount}</p>
                            <Button variant="success" onClick={handleSaveEmails} disabled={loading}>
                                Inserir Dados no Banco de Dados
                            </Button>
                        </div>
                    )}
                    {error && (
                        <Alert variant="danger" style={{ marginTop: '10px' }}>
                            {error}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose} disabled={loading}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de aviso de longa duração */}
            <Modal show={showLongProcessWarning} onHide={() => setShowLongProcessWarning(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Aviso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        A verificação está demorando mais do que o esperado. Por favor, não feche o navegador, 
                        notificaremos quando o procedimento terminar.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLongProcessWarning(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImportCSVModal;
