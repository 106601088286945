import React, { useEffect } from 'react';
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ChangePasswordModal = ({ show, onClose, userId, forceChangePassword }) => {

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres.')
            .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
            .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
            .matches(/\d/, 'A senha deve conter pelo menos um número.')
            .matches(/[@#$!%*?&]/, 'A senha deve conter pelo menos um caractere especial (@,#, $, !, %, *, ?, &).')
            .required('Nova senha é obrigatória.'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'A nova senha e a confirmação não coincidem.')
            .required('A confirmação da nova senha é obrigatória.'),
    });

    useEffect(() => {
        if (forceChangePassword && !show) {
            setTimeout(() => onClose(), 100);
        }
    }, [show, forceChangePassword, onClose]);

    const handlePasswordChange = async (values, { setSubmitting, setStatus }) => {
        setStatus({ error: null, success: null });

        try {
            const token = localStorage.getItem('auth-token');

            if (!token) {
                setStatus({ error: 'Token não encontrado. Por favor, faça login novamente.' });
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api-dashflow/change-password`, {
                userId,
                newPassword: values.newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setStatus({ success: 'Senha alterada com sucesso.' });
                if (forceChangePassword) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            } else {
                setStatus({ error: response.data.message || 'Erro ao alterar a senha.' });
            }
        } catch (error) {
            setStatus({
                error: error.response?.data?.message || 'Erro ao alterar a senha.',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={forceChangePassword ? null : onClose}>
            <Modal.Header closeButton={!forceChangePassword}>
                <Modal.Title>Trocar Senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ newPassword: '', confirmPassword: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handlePasswordChange}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {status?.error && <Alert variant="danger">{status.error}</Alert>}
                            {status?.success && <Alert variant="success">{status.success}</Alert>}
                            
                            <Form.Group controlId="newPassword" className="mt-3">
                                <Form.Label>Nova Senha</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    placeholder="Digite sua nova senha"
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.newPassword && !!errors.newPassword}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.newPassword}
                                </Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    A senha deve ter no mínimo 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="confirmPassword" className="mt-3">
                                <Form.Label>Confirme a Nova Senha</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirme sua nova senha"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-4">
                                {isSubmitting ? 'Alterando...' : 'Alterar Senha'}
                            </Button>
                        </Form>
                    )}
                </Formik>
                {forceChangePassword && (
                    <Alert variant="warning" className="mt-3">
                        Para sua segurança, é necessário que você troque a senha.
                    </Alert>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ChangePasswordModal;
