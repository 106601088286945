import React, { useState, useEffect, useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button, Table, InputGroup, Form, Card, Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserSlash, faTrashAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import UserStatsChart from './UserStatsChart';
import EditUserModal from './EditUserModal';
import CreateUserModal from './CreateUserModal';
import ImportCSVModal from './ImportCSVModal';
import '../../App.css';

const InfoCard = ({ title, textValue }) => (
  <Card className="info-card">
    <Card.Body className="card-body">
      <div className="info-card-details">
        <Card.Title className="title">
          {title}: <span className="text-value">{textValue}</span>
        </Card.Title>
      </div>
    </Card.Body>
  </Card>
);

const Users = () => {
  const { token } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [adminUsers, setAdminUsers] = useState(0);
  const [activeUsersByCluster, setActiveUsersByCluster] = useState({});
  const [userLimit, setUserLimit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [editUserId, setEditUserId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 20;
  const [filters, setFilters] = useState({ status: '', name: '', email: '', cluster: '' });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/api-dashflow/users', {
          headers: { 'x-api-token': token },
        });
        const usersData = response.data;
        setUsers(usersData);
        setFilteredUsers(usersData);

        const activeUsersCount = usersData.filter(user => user.status).length;
        const inactiveUsersCount = usersData.filter(user => !user.status).length;
        const adminUsersCount = usersData.filter(user => user.access_level === 1).length;
        setActiveUsers(activeUsersCount);
        setInactiveUsers(inactiveUsersCount);
        setAdminUsers(adminUsersCount);

        const activeByCluster = usersData
          .filter(user => user.status)
          .reduce((acc, user) => {
            acc[user.cluster] = (acc[user.cluster] || 0) + 1;
            return acc;
          }, {});
        setActiveUsersByCluster(activeByCluster);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [token]);

  useEffect(() => {
    const fetchUserLimit = async () => {
      try {
        const response = await api.get('/api-dashflow/license-limit', {
          headers: { 'x-api-token': token },
        });
        const { limit } = response.data;
        setUserLimit(limit);
      } catch (error) {
        console.error('Error fetching user limit:', error);
      }
    };

    fetchUserLimit();
  }, [token]);

  useEffect(() => {
    applyFilters(users, filters, setFilteredUsers);
  }, [filters, users]);

  const applyFilters = (users, filters, setFilteredUsers) => {
    const { status, name, email, cluster } = filters;
    const filtered = users.filter(user => {
      return (
        (!status || (status === 'active' && user.status) || (status === 'inactive' && !user.status)) &&
        (!name || user.name.toLowerCase().includes(name.toLowerCase())) &&
        (!email || user.email.toLowerCase().includes(email.toLowerCase())) &&
        (!cluster || user.cluster.toLowerCase().includes(cluster.toLowerCase()))
      );
    });
    setFilteredUsers(filtered);
  };

  const handleNewUser = () => {
    setShowCreateModal(true);
  };

  const handleEditUser = (userId) => {
    setEditUserId(userId);
    setShowEditModal(true);
  };

  const handleDeleteUser = (userId) => {
    setCurrentUser(userId);
    setConfirmAction('delete');
    setShowConfirmModal(true);
  };

  const handleToggleUserStatus = (userId) => {
    setCurrentUser(userId);
    setConfirmAction('toggle');
    setShowConfirmModal(true);
  };

  const confirmActionHandler = async () => {
    if (confirmAction === 'toggle') {
      try {
        const user = users.find(user => user.id === currentUser);
        const updatedStatus = !user.status; // Alterna o status corretamente
  
        // Envie todos os campos necessários na requisição
        const response = await api.put(`/api-dashflow/users/${currentUser}`, 
          { 
            name: user.name,
            email: user.email,
            cluster: user.cluster,
            access_level: user.access_level,
            status: updatedStatus // Modifique o status no backend
          }, 
          {
            headers: { 'x-api-token': token },
          }
        );
  
        // Atualize corretamente o status no frontend após a resposta do backend
        setUsers(users.map(user => 
          user.id === currentUser ? { ...user, status: updatedStatus } : user
        ));
        setFilteredUsers(filteredUsers.map(user => 
          user.id === currentUser ? { ...user, status: updatedStatus } : user
        ));
        
      } catch (error) {
        console.error('Error toggling user status:', error);
      }
    } else if (confirmAction === 'delete') {
      try {
        // Faça a requisição DELETE para o servidor
        const response = await api.delete(`/api-dashflow/users/${currentUser}`, {
          headers: { 'x-api-token': token },
        });
  
        if (response.data.success) {
          // Atualiza o estado para remover o usuário deletado
          const updatedUsers = users.filter(user => user.id !== currentUser);
          setUsers(updatedUsers);
          setFilteredUsers(updatedUsers);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
    setShowConfirmModal(false); // Fecha o modal independente do resultado
  };
  
  
  
  

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowCreateModal(false);
    setShowImportModal(false);
  };

  const loadMoreUsers = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = 0;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const headers = [
    { label: "Nome", key: "name" },
    { label: "Email", key: "email" },
    { label: "Cluster", key: "cluster" },
    { label: "Status", key: "status" },
    { label: "Nível de Acesso", key: "access_level" },
  ];

  return (
    <div className="users-container">
      <h1>Gestão de Usuários</h1>

      <div className="users-info">
        <InfoCard
          title="Usuários Ativos"
          textValue={activeUsers}
        />
        <InfoCard
          title="Usuários Inativos"
          textValue={inactiveUsers}
        />
        <InfoCard
          title="Administradores"
          textValue={adminUsers}
        />
        <InfoCard
          title="Limite de Usuários"
          textValue={userLimit !== null ? `${activeUsers + inactiveUsers} / ${userLimit}` : 'Carregando...'}
        />
      </div>

      <hr className="section-divider" />

      <UserStatsChart
        activeUsers={activeUsers}
        inactiveUsers={inactiveUsers}
        adminUsers={adminUsers}
        activeUsersByCluster={activeUsersByCluster}
      />

      <hr className="section-divider" />

      <div className="users-header">
        <div className="button-group">
          <Button className="btn-new-user" onClick={handleNewUser}>
            Novo Usuário
          </Button>
          <Button onClick={() => setShowImportModal(true)}>
            Importar Planilha
          </Button>
          <CSVLink data={filteredUsers} headers={headers} filename="usuarios.csv" className="btn-secondary">
            Exportar Dados
          </CSVLink>
        </div>
        <div className="users-filters">
          <InputGroup>
            <Form.Select name="status" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
              <option value="">Status</option>
              <option value="active">Ativo</option>
              <option value="inactive">Inativo</option>
            </Form.Select>
            <Form.Control
              placeholder="Nome"
              name="name"
              value={filters.name}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            />
            <Form.Control
              placeholder="Email"
              name="email"
              value={filters.email}
              onChange={(e) => setFilters({ ...filters, email: e.target.value })}
            />
            <Form.Select name="cluster" value={filters.cluster} onChange={(e) => setFilters({ ...filters, cluster: e.target.value })}>
              <option value="">Cluster</option>
              {Array.from(new Set(users.map(user => user.cluster))).map(cluster => (
                <option key={cluster} value={cluster}>{cluster}</option>
              ))}
            </Form.Select>
          </InputGroup>
        </div>
      </div>

      <Table striped bordered hover className="ctn-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Nome</th>
            <th>Email</th>
            <th>Cluster</th>
            <th>Nível de Acesso</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.status ? 'Ativo' : 'Inativo'}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.cluster}</td>
              <td>{user.access_level}</td>
              <td className="ctn-button">
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-edit-${user.id}`}>Editar Usuário</Tooltip>}
  >
    <Button className="btn-edit" onClick={() => handleEditUser(user.id)}>
      <FontAwesomeIcon icon={faCog} />
    </Button>
  </OverlayTrigger>

  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-delete-${user.id}`}>Deletar Usuário</Tooltip>}
  >
    <Button className="btn-delete" onClick={() => handleDeleteUser(user.id)}>
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  </OverlayTrigger>

  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-toggle-${user.id}`}>
      {user.status ?  'Ativar Usuário':'Desativar Usuário' }
    </Tooltip>}
  >
    <Button className="btn-toggle" onClick={() => handleToggleUserStatus(user.id)}>
      <FontAwesomeIcon icon={user.status ? faUserSlash : faUserCheck} />
    </Button>
  </OverlayTrigger>
</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {indexOfLastUser < filteredUsers.length && (
        <div className="load-more">
          <Button onClick={loadMoreUsers} className="btn btn-primary">Mostrar mais 20</Button>
        </div>
      )}

      {showEditModal && (
        <EditUserModal
          show={showEditModal}
          userId={editUserId}
          onClose={handleCloseModal}
        />
      )}

      {showCreateModal && (
        <CreateUserModal
          show={showCreateModal}
          onClose={handleCloseModal}
        />
      )}

      {showImportModal && (
        <ImportCSVModal
          show={showImportModal}
          onClose={handleCloseModal}
        />
      )}

      {showConfirmModal && (
        <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmAction === 'delete'
              ? 'Tem certeza que deseja deletar este usuário? Esta ação não pode ser desfeita.'
              : 'Tem certeza que deseja alterar o status deste usuário?'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={confirmActionHandler}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Users;
