import React, { useState, useRef, useContext } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import loadingGif from '../../assets/Loading.gif';
import Papa from 'papaparse';

const ImportDashboardModal = ({ show, onClose }) => {
    const { token } = useContext(AuthContext);
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileClick = () => {
        setError('');
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csv')) {
                setFileName(file.name);
                setError('');
            } else {
                setFileName('');
                setError('Por favor, selecione um arquivo CSV.');
            }
        }
    };

    const analyzeImport = async (data) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api-dashflow/dashboards`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.data || !Array.isArray(response.data)) {
                throw new Error('Resposta do servidor não é válida.');
            }
    
            const existingDashboards = response.data;
            const dashboardsToAdd = [];
            const dashboardsToUpdate = [];
    
            for (const dashboard of data) {
                // Normaliza o Report Id removendo o cluster
                const reportIdWithoutCluster = dashboard['Report Id'].split('.')[0];
    
                // Tenta encontrar um dashboard existente sem considerar o cluster
                const existingDashboard = existingDashboards.find(
                    (d) => d.report_id.split('.')[0] === reportIdWithoutCluster
                );
    
                if (existingDashboard) {
                    dashboardsToUpdate.push(dashboard);
                } else {
                    dashboardsToAdd.push(dashboard);
                }
            }
    
            setResults({
                dashboardsToAdd,
                dashboardsToUpdate,
            });
    
        } catch (error) {
            console.error('Erro ao analisar o arquivo CSV:', error);
            setError('Erro ao analisar o arquivo CSV.');
        }
    };
    
    const handleCheckDashboards = async () => {
        setLoading(true);
        setError('');

        const file = fileInputRef.current.files[0];
        if (!file) {
            setError('Por favor, selecione um arquivo CSV válido.');
            setLoading(false);
            return;
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (result) => {
                await analyzeImport(result.data);
                setLoading(false);
            }
        });
    };

    const handleImportDashboards = async () => {
        if (!fileInputRef.current.files[0]) {
            setError('Por favor, selecione um arquivo CSV.');
            return;
        }
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('file', fileInputRef.current.files[0]);
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api-dashflow/dashboards/import`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
    
            if (response.status === 200) {
                alert('Dashboards importados com sucesso!');
                onClose();
            } else {
                setError('Erro ao salvar os dados no banco de dados.');
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            setError('Erro ao salvar os dados no banco de dados.');
        }
    
        setLoading(false);
    };
    
    

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Importar Dashboards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".csv"
                />
                <Button variant="primary" onClick={handleFileClick} disabled={loading}>
                    Selecione o arquivo CSV
                </Button>
                {fileName && (
                    <div style={{ marginTop: '10px' }}>
                        <p><strong>Nome do arquivo:</strong> {fileName}</p>
                        <Button variant="secondary" onClick={handleCheckDashboards} disabled={loading}>
                            Verificar Dashboards
                        </Button>
                    </div>
                )}
                {loading && (
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <img src={loadingGif} alt="Loading..." style={{ width: '160px', height: '90px' }} />
                    </div>
                )}
                {results && (
                    <div style={{ marginTop: '10px' }}>
                        <p><strong>Dashboards a serem adicionados:</strong> {results.dashboardsToAdd.length}</p>
                        <p><strong>Dashboards a serem atualizados:</strong> {results.dashboardsToUpdate.length}</p>
                        <Button variant="success" onClick={handleImportDashboards} disabled={loading}>
                            Inserir Dados no Banco de Dados
                        </Button>
                    </div>
                )}
                {error && (
                    <Alert variant="danger" style={{ marginTop: '10px' }}>
                        {error}
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={loading}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImportDashboardModal;
