import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './Login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext'; // Importe o contexto de autenticação

const FirstLogin = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); // Usando o contexto de autenticação

  // Validação do esquema de senha
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'A senha deve ter no mínimo 8 caracteres.')
      .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
      .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
      .matches(/\d/, 'A senha deve conter pelo menos um número.')
      .matches(/[@#$!%*?&]/, 'A senha deve conter pelo menos um caractere especial (@,#, $, !, %, *, ?, &).')
      .required('Nova senha é obrigatória.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'A nova senha e a confirmação não coincidem.')
      .required('A confirmação da nova senha é obrigatória.'),
  });

  const handlePasswordChange = async (values, { setSubmitting, setStatus }) => {
    const authToken = localStorage.getItem('auth-token');
    if (!authToken) {
      setErrorMessage('Erro de autenticação. Por favor, faça login novamente.');
      setTimeout(() => logout(), 5000); // Usando a função de logout do contexto
      return;
    }

    try {
      const userId = JSON.parse(localStorage.getItem('user'))?.id;
      const response = await api.post('/api-login/change-password', {
        userId,
        newPassword: values.newPassword,
      }, {
        headers: { Authorization: `Bearer ${authToken}` }
      });

      if (response.status === 200) {
        setSuccessMessage('Senha alterada com sucesso!');
        setTimeout(() => navigate('/dashboard'), 2000);
      } else {
        setErrorMessage('Erro ao alterar a senha.');
        setStatus({ error: response.data.message || 'Erro ao alterar a senha.' });
      }
    } catch (error) {
      console.error('Error during password change:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao alterar a senha.');
      setStatus({ error: error.response?.data?.message || 'Erro ao alterar a senha.' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    logout(); // Usando a função de logout do contexto para garantir limpeza completa
    navigate('/login'); // Redireciona para a tela de login
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-box">
          <h2>Primeiro Acesso</h2>
          <p>Por favor, altere sua senha antes de continuar.</p>
          <img src="/logo-cliente.png" alt="Logo" className="logo" />

          <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={handlePasswordChange}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              status,
            }) => (
              <form onSubmit={handleSubmit} className="login-form">
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}

                <div className="form-group">
                  <label htmlFor="newPassword">Nova Senha</label>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    className={`form-control ${touched.newPassword && errors.newPassword ? 'is-invalid' : ''}`}
                    autoComplete="new-password"
                  />
                  {touched.newPassword && errors.newPassword && (
                    <div className="invalid-feedback">{errors.newPassword}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirme a Nova Senha</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    className={`form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                    autoComplete="new-password"
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <div className="invalid-feedback">{errors.confirmPassword}</div>
                  )}
                </div>

                <div className="form-actions">
                  <button type="submit" className="btn btn-brown" disabled={isSubmitting}>
                    {isSubmitting ? 'Alterando...' : 'Salvar Nova Senha'}
                  </button>
                  <button type="button" className="btn btn-secondary ml-2" onClick={handleCancel}>
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FirstLogin;
