import React, { useState, useContext } from 'react';
import { Modal, Button, Form, InputGroup, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';

const CreateUserModal = ({ show, onClose }) => {
    const { token } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: 'Hortifrut@123',
        cluster: '',
        access_level: 2,
        status: true // Valor padrão true para status
    });


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (!formData.name || !formData.email || !formData.cluster) {
            setError('Todos os campos são obrigatórios, incluindo o cluster.');
            setLoading(false);
            return;
        }

        const userData = { ...formData };
        try {
            await api.post('/api-dashflow/users', userData, {
                headers: { 'auth-token': token }
            });
            setSuccess('Usuário criado com sucesso!');
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setError('Erro ao criar usuário: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Criar Novo Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                <Form onSubmit={handleSubmit} className='ctn-form'>
                    <Form.Group controlId="formName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>Senha</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Senha"
                                value={formData.password}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                required
                            />
                            <Button variant="outline-secondary" onClick={toggleShowPassword}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formCluster">
                        <Form.Label>Cluster</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Cluster"
                            value={formData.cluster}
                            onChange={(e) => setFormData({ ...formData, cluster: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formAccessLevel">
                        <Form.Label>Nível de Acesso</Form.Label>
                        <Form.Control
                            as="select"
                            value={formData.access_level}
                            onChange={(e) => setFormData({ ...formData, access_level: e.target.value })}
                        >
                            <option value={1}>Admin</option>
                            <option value={2}>Usuário</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formStatus" className='checkbox'>
                        <input
                            type="checkbox"
                            label="Ativo"
                            className='custom-checkbox'
                            checked={formData.status}
                            onChange={(e) => setFormData({ ...formData, status: e.target.checked })}
                        />
                        <label htmlFor="ativoCheckbox">Ativo</label>
                    </Form.Group>

                    <Button style={{ background: "#633554", border: 'none' }} type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Criar Usuário'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateUserModal;
